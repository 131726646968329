import { useEffect } from 'react';
import {
  Flex,
  Spin,
  Button,
  Tooltip,
  Badge,
  Space,
  Switch,
  Popover,
  Form,
} from 'antd';
import Table from 'common/Table';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { createMaterial } from 'features/Admin/redux/slice';
import { openNotification } from 'utilities/notification';
import MaterialModal from './MaterialModal';

type ListProps = {
  searchMaterial: string;
  materialList: any;
  materialListLoading: boolean;
  handelGetMaterialList: any;
  pagination: any;
  currentPage: number;
  setCurrentPage: any;
  PER_PAGE_ITEM: number;
  addMaterialLoading: boolean;
  type: string;
};

export default function ListView({
  searchMaterial,
  materialList,
  handelGetMaterialList,
  materialListLoading,
  pagination,
  currentPage,
  setCurrentPage,
  PER_PAGE_ITEM,
  addMaterialLoading,
  type,
}: ListProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idToUpdate, setIdToUpdate] = useState<number | null>(null);
  const [userDataById, setUserDataById] = useState({});

  interface payload {
    id: number;
    is_active: boolean;
  }

  const handleModalOpen = (record: any) => {
    setIsModalOpen(true);
    setUserDataById(record);
  };

  const handleModalClose = () => setIsModalOpen(false);

  const handleUpdateMaterialState = (id: number) => {
    const payload: payload = {
      id,
      is_active: type === 'active' ? false : true,
    };
    dispatch(createMaterial(payload)).then((res: any) => {
      if (res.payload.success) {
        setIdToUpdate(null);
        handelGetMaterialList();
      }
    });
  };

  const contentForChangingMaterialState = (
    <Form>
      <div className="flex flex-col gap-2">
        <span className="text-xs font-semibold font-open-sans">
          Do you want to {type === 'active' ? 'disable' : 'enable'} this
          material?
        </span>
      </div>
      <div className="flex justify-around mt-4 ">
        <Button
          size="small"
          className="font-medium text-xs font-open-sans hover:!text-primary border hover:!border-primary"
          onClick={() => setIdToUpdate(null)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="small"
          className="font-medium text-xs text-white font-open-sans hover:!text-primary hover:!bg-transparent bg-primary border hover:!border-primary"
          onClick={() => handleUpdateMaterialState(idToUpdate as number)}
          loading={addMaterialLoading}
        >
          Confirm
        </Button>
      </div>
    </Form>
  );

  const columns = [
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Material Name
        </label>
      ),
      dataIndex: 'material_name',
      key: 'material_name',
      width: '7%',
      render: (text: string, record: any, index: number) => {
        return (
          <>
            <p className="relative text-xs !px-0 font-open-sans text-tertiary-filter text-transform-capitalize">
              {text}
            </p>
          </>
        );
      },
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          CAS Number
        </label>
      ),
      dataIndex: 'cas_number',
      key: 'cas_number',
      width: '7%',
      render: (text: string, record: any, index: number) => {
        return (
          <>
            <p className="relative capitalize text-xs !px-0 font-open-sans text-tertiary-filter text-transform-capitalize">
              {text}
            </p>
          </>
        );
      },
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Mol Formula
        </label>
      ),
      dataIndex: 'mol_formula',
      key: 'mol_formula',
      width: '7%',
      render: (text: string, record: any, index: number) => {
        return (
          <>
            <p className="relative capitalize text-xs !px-0 font-open-sans text-tertiary-filter text-transform-capitalize">
              {text}
            </p>
          </>
        );
      },
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Mol Weight
        </label>
      ),
      dataIndex: 'mol_weight',
      key: 'mol_weight',
      width: '7%',
      render: (text: string, record: any, index: number) => {
        return (
          <>
            <p className="relative capitalize text-xs !px-0 font-open-sans text-tertiary-filter text-transform-capitalize">
              {text}
            </p>
          </>
        );
      },
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Action
        </label>
      ),
      dataIndex: 'action',
      key: 'action',
      width: '8%',
      render: (_: any, record: any) => (
        <Space>
          {type === 'active' && (
            <Tooltip
              title={'Edit'}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            >
              <EditOutlined
                // onClick={handleModalOpen}
                onClick={() => handleModalOpen(record)}
                className="text-xs cursor-pointer hover:opacity-75 text-tertiary-filter hover:text-primary-dark"
              />
            </Tooltip>
          )}
          <Popover
            title=""
            open={idToUpdate === record.key}
            content={contentForChangingMaterialState}
            trigger={['click']}
            placement="topLeft"
            overlayClassName=" w-1/4"
          >
            <Tooltip
              title={type === 'active' ? 'Disable' : 'Enable'}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            >
              <Switch
                size="small"
                value={type === 'active'}
                onChange={() => setIdToUpdate(record.key)}
              />
            </Tooltip>
          </Popover>
        </Space>
      ),
    },
  ];

  const dispatch: AppDispatch = useDispatch();

  const transformedUomList = materialList?.map((item: any) => {
    return {
      key: item?.id,
      material_name: item?.material_name,
      cas_number: item?.cas_number,
      mol_formula: item?.mol_formula,
      mol_weight: item?.mol_weight,
    };
  });

  return (
    <Spin spinning={addMaterialLoading}>
      <Flex className="relative h-full m-2 overflow-auto sticky-footer">
        <Table
          totalPages={pagination?.total_pages}
          pagination={true}
          pageSize={PER_PAGE_ITEM}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          columns={columns}
          dataSource={transformedUomList}
        />
        <MaterialModal
          isEdit={true}
          addMaterialLoading={addMaterialLoading}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          handleGetMaterialList={handelGetMaterialList}
          userDataById={userDataById}
        />
      </Flex>
    </Spin>
  );
}
