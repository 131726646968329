import {
  Button,
  Col,
  Divider,
  Empty,
  Flex,
  Form,
  FormInstance,
  Row,
  Spin,
  Tag,
  Tooltip,
} from 'antd';
import { AppDispatch, RootState } from 'app/store';
import Card from 'common/Card';
import FormInput from 'common/FormInput';
import Label from 'common/Label';
import Table from 'common/Table';
import { getUomList } from 'features/proposalLeadFlow/projects/redux/slice';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addProduct,
  createExperiment,
  getExperimentsDetails,
  getProductsList,
  setExperimentId,
} from '../redux/slice';
import deleteIcon from 'assets/icons/delete.svg';
import { LoadingOutlined } from '@ant-design/icons';
import { getLoggedInUser, SECTION } from 'utilities/helpers';
import { STATUS } from 'utilities/helpers';
import { getFolderDetails } from 'features/sectionHeadFlow/redux/slice';
import SectionHeader from 'common/SectionHeader';
import {
  convertTextWithChemicalFormulas,
  convertChemicalFormulasToSimpleText,
} from 'utilities/helpers';
import noProductIcon from 'assets/icons/no_product.svg';
import RemarksModal from 'common/RemarksModal';
import CommentsList from './CommentsList';

type TProps = {
  children?: string | JSX.Element | undefined;
  isCreate: boolean;
  isView: boolean;
  parentForm: FormInstance<any>;
  showForm: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  projectId: string | number;
  productList: any[];
  experimentId: string | null;
  folderId: string | undefined;
  experimentStatus: string;
  createExperimentLoading: boolean;
  folderDetails: any;
  unsavedSections: string[];
  setUnsavedSections: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function Comments({
  children,
  isCreate,
  isView,
  parentForm,
  showForm,
  setShowForm,
  projectId,
  productList,
  experimentId,
  folderId,
  experimentStatus,
  createExperimentLoading,
  folderDetails,
  unsavedSections,
  setUnsavedSections,
}: TProps) {
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const [isUpdateComments, setIsUpdateComments] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [formattedInput, setFormattedInput] = useState<string>(''); // Formatted input state

  const [form] = Form.useForm();
  const { uomList } = useSelector((state: RootState) => state.project2);
  const { addProductLoading, experimentDetails, isExternalChemist } =
    useSelector((state: RootState) => state.experiments);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  //updating comments section

  const updateComments = (values: any) => {
    const data = { ...experimentDetails, ...folderDetails };
    const payload = {
      project_id: projectId,
      experiment_id: experimentId,
      folder_id: folderId,
      references: data.references,
      step_number: data.step_number,
      folder_route_scheme_id: data.folder_route_scheme_id,
      objective_of_reaction: convertChemicalFormulasToSimpleText(
        data.objective_of_reaction
      ),
      is_product_formed: data?.is_product_formed,
      // product_name: !showForm ? values.product_name : '',
      remarks: convertChemicalFormulasToSimpleText(values.remarks),
      reaction_scheme: data.reaction_scheme,
      status: experimentStatus as string,
      change_remarks: values.remark,
      section: 'comments',
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
      experiment_category: data.experiment_category,
      re_submit: false,
      experiment_precautions: data?.experiment_precautions,
    };

    let formdata = new FormData();

    formdata.append('project_id', payload.project_id as string);
    formdata.append('experiment_id', payload.experiment_id as string);
    formdata.append('is_edit', payload.is_edit ? 'true' : 'false');
    formdata.append('re_submit', 'false');
    formdata.append('folder_id', payload.folder_id as string);
    formdata.append('references', payload.references);
    formdata.append('step_number', payload.step_number);
    formdata.append('experiment_category', payload.experiment_category);
    formdata.append(
      'folder_route_scheme_id',
      String(payload.folder_route_scheme_id)
    );
    formdata.append(
      'objective_of_reaction',
      convertChemicalFormulasToSimpleText(payload.objective_of_reaction)
    );
    formdata.append(
      'is_product_formed',
      payload.is_product_formed ? 'true' : 'false'
    );
    formdata.append(
      'remarks',
      convertChemicalFormulasToSimpleText(payload.remarks)
    );
    formdata.append('reaction_scheme', payload.reaction_scheme);
    formdata.append('status', payload.status);
    formdata.append('change_remarks', payload.change_remarks);
    formdata.append('section', payload.section);
    formdata.append('experiment_precautions', payload.experiment_precautions);

    dispatch(createExperiment(formdata)).then((res: any) => {
      setIsUpdateComments(false);
      setIsRemarksModalOpen(false);
      if (res?.payload?.success) {
        setIsEditing(false);
        dispatch(getExperimentsDetails({ id: experimentId })).then(
          (res: any) => {
            if (res?.payload.success) {
              dispatch(getFolderDetails({ id: folderId }));
            }
          }
        );
      }
    });
  };

  const handleUpdateComments = (remark: string) => {
    form
      .validateFields()
      .then((values: any) => {
        if (isRemarksModalOpen) {
          const parentValues = parentForm.getFieldsValue();
          updateComments({ remark, ...parentValues });
        } else {
          setIsRemarksModalOpen(true);
        }
      })
      .catch(() => {});
  };

  const handleSave = () => {
    if (isEditing) {
      setIsUpdateComments(true);
      form
        .validateFields()
        .then((values: any) => {
          setUnsavedSections(
            unsavedSections.filter((sec) => sec !== SECTION.comments)
          );
          if (isRemarksModalOpen) return;
          setIsRemarksModalOpen(true);
        })
        .catch(() => {});
    } else {
      setShowForm(true);
      setIsEditing(true);
      setUnsavedSections([...unsavedSections, SECTION.comments]);
    }
  };

  const renderedUOMList = uomList?.map((uom) => {
    return { label: uom.uom_code, value: uom.id };
  });

  useEffect(() => {
    const payloadUOM = {
      search: searchTerm,
    };
    dispatch(getUomList(payloadUOM));
  }, [searchTerm]);

  const handleUomSearch = useCallback(
    debounce((val: string) => {
      setSearchTerm(val);
    }, 500),
    []
  );

  const onUomSearch = (val: string) => {
    handleUomSearch(val);
  };

  const handleAddProduct = (values: any) => {
    const payload = {
      ...values,
      project_id: projectId,
      uom_id: values.uom_master_id,
      mol_formula: convertChemicalFormulasToSimpleText(
        values.molecular_formula
      ),
      mol_weight: values.mol_wt,
      experiment_id: experimentId || null,
      folder_id: folderId,
      change_remarks: values.remark,
      section: 'comments',
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
    };

    dispatch(addProduct(payload)).then((res: any) => {
      if (res?.payload?.success) {
        setIsRemarksModalOpen(false);
        const experiment_id = res?.payload?.experiment_id || experimentId;
        form.resetFields();
        form.setFieldsValue({ product_formed: 1 });
        dispatch(setExperimentId(experiment_id));
        parentForm.setFieldsValue({
          experiment_id: experiment_id,
        });
        dispatch(getProductsList({ project_id: projectId, experiment_id }));
      }
    });
  };

  const handleSubmit = (remark: string) => {
    form
      .validateFields()
      .then((values) => {
        if (isCreate || isView) {
          handleAddProduct({ remark, ...values });
        } else {
          if (isRemarksModalOpen) {
            handleAddProduct({ remark, ...values });
          } else {
            setIsRemarksModalOpen(true);
          }
        }
      })
      .catch(() => {});
  };

  // setting comments form state to true by default
  useEffect(() => {
    const values = form.getFieldsValue();
    if (values.product_formed === undefined) {
      form.setFieldsValue({ product_formed: 1 });
    }
    // if(values.molecular_formula) {
    //   form.setFieldsValue({ product_formed: 1 });
    // }
  }, []);

  const handleFormValuesChange = (changedVal: any, values: any) => {
    // if (changedVal.product_name || changedVal.remarks) {
    //   parentForm.setFieldsValue({
    //     product_name: values.product_name,
    //     remarks: values.remarks,
    //   });
    // }
    if (changedVal.molecular_formula) {
      setFormattedInput(changedVal.molecular_formula);
    }

    if (changedVal.product_formed === 1) {
      setShowForm(true);
    }

    if (changedVal.product_formed === 0) {
      setShowForm(false);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === ' ') {
      // Check for spacebar key
      form.setFieldsValue({
        molecular_formula: convertTextWithChemicalFormulas(formattedInput),
      });
    }
  };

  return (
    <Card
      header={
        <SectionHeader
          isEditing={isEditing}
          disabled={isDisabled}
          isView={isView}
          onSave={handleSave}
          experimentStatus={experimentStatus}
          title="Comments"
          saveLoading={createExperimentLoading}
          experimentId={experimentId}
        />
      }
    >
      <div className="flex flex-col gap-3 p-6">
        <Form
          id="comment_form"
          onFinish={handleAddProduct}
          onValuesChange={handleFormValuesChange}
          onKeyDown={handleKeyDown}
          form={form}
          layout="vertical"
        >
          {(isCreate || (isView && isEditing)) && (
            <>
              <Row gutter={20}>
                <Col span={24} md={{ span: 12 }} lg={{ span: 6 }}>
                  <FormInput
                    name="product_formed"
                    required
                    validateMsg="Product Formed is required"
                    label="Required Product Formed"
                    placeholder="Select"
                    type="radio"
                    options={[
                      {
                        label: 'Yes',
                        value: 1,
                        classNames:
                          'border border-tertiary-highlight-200 p-1 rounded-md',
                      },
                      {
                        label: 'No',
                        value: 0,
                        classNames:
                          'border border-tertiary-highlight-200 p-1 rounded-md ',
                      },
                    ]}
                    inputClasses=" "
                  />
                </Col>
                {showForm && (
                  <>
                    <Col span={24} md={{ span: 12 }} lg={{ span: 6 }}>
                      <FormInput
                        name="product_name"
                        label="Product Name"
                        placeholder="Enter product"
                      />
                    </Col>
                    <Col span={24} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Row>
                        <Col span={16}>
                          <FormInput
                            name="quantity"
                            type="number"
                            label={
                              <span className="text-tertiary-dark">
                                Quantity
                              </span>
                            }
                            inputClasses="p-select font-open-sans shadow-none border focus-within:border-primary-dark hover:border-tertiary-highlight-200 rounded-e-none"
                            defaultStyle={false}
                            placeholder="Enter quantity"
                          />
                        </Col>
                        <Col span={8}>
                          <FormInput
                            popupClassName="select-dropdown"
                            name="uom_master_id"
                            label={<div className="invisible">uom</div>}
                            onSearch={onUomSearch}
                            type="select"
                            defaultStyle={false}
                            placeholder="UOM"
                            options={renderedUOMList}
                            selectInputClasses="selected-item-transform border-none focus-within:border-none  placeholder:text-primary select-placeholder"
                            wrapperClasses="rounded-e-md hover:border-tertiary-highlight-200 border-tertiary-highlight-200 border focus-within:border-primary-dark"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} md={{ span: 12 }} lg={{ span: 4 }}>
                      <FormInput
                        name="purity"
                        label={
                          <span className="text-tertiary-dark">Purity</span>
                        }
                        defaultStyle={false}
                        placeholder="Enter purity"
                      />
                    </Col>
                  </>
                )}
              </Row>
              {showForm && (
                <Row gutter={20}>
                  <Col span={24} md={{ span: 12 }} lg={{ span: 5 }}>
                    <FormInput
                      name="mol_wt"
                      type="number"
                      label={<span className="text-tertiary-dark">MW</span>}
                      defaultStyle={false}
                      placeholder="Enter MW"
                    />
                  </Col>
                  <Col span={24} md={{ span: 12 }} lg={{ span: 5 }}>
                    <FormInput
                      name="molecular_formula"
                      label={
                        <span className="text-tertiary-dark">Mol. Formula</span>
                      }
                      defaultStyle={false}
                      placeholder="Enter mol. formula"
                    />
                  </Col>
                  <Col span={24} md={{ span: 12 }} lg={{ span: 5 }}>
                    <FormInput
                      name="mol_yield"
                      type="number"
                      label={
                        <span className="text-tertiary-dark">
                          Mol. Yield (%)
                        </span>
                      }
                      defaultStyle={false}
                      placeholder="Mol. yield"
                    />
                  </Col>
                  <Col span={24} md={{ span: 12 }} lg={{ span: 5 }}>
                    <FormInput
                      name="actual_yield"
                      type="number"
                      label={
                        <span className="text-tertiary-dark">
                          Actual Yield (wt/wt)
                        </span>
                      }
                      defaultStyle={false}
                      placeholder="Actual yield"
                    />
                  </Col>
                  <Col span={24} md={{ span: 12 }} lg={{ span: 4 }}>
                    <FormInput
                      name="th_yield"
                      type="number"
                      label={
                        <span className="text-tertiary-dark">
                          Th. Yield (wt/wt)
                        </span>
                      }
                      defaultStyle={false}
                      placeholder="Th. yield"
                    />
                  </Col>
                </Row>
              )}

              {showForm && (
                <Flex wrap gap={10} justify="center" className="m-3">
                  <>
                    <Button
                      onClick={() => form.resetFields()}
                      className="text-sm font-open-sans hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
                    >
                      Clear
                    </Button>
                    <Button
                      onClick={() => handleSubmit('')}
                      type="primary"
                      className="text-sm font-semibold bg-transparent border font-open-sans sm:w-auto text-primary border-primary hover:text-white hover:!bg-primary"
                      loading={addProductLoading}
                    >
                      Add Product Values
                    </Button>
                  </>
                </Flex>
              )}
            </>
          )}
        </Form>
        {isView && !productList.length && (
          <Empty
            description="No Product Formed!"
            className="flex flex-col items-center justify-center"
            image={<img src={noProductIcon} />}
          />
        )}
        {productList.length ? (
          <CommentsList
            isExternalChemist={isExternalChemist as boolean}
            isCreate={isCreate}
            isView={isView}
            isEditing={isEditing}
            projectId={projectId}
            experimentId={experimentId}
            loading={addProductLoading}
            datasource={productList}
            setIsDisabled={setIsDisabled}
          />
        ) : null}
        <FormInput
          name="remarks"
          label="Remarks"
          defaultStyle
          disabled={isView && !isEditing}
          type="textarea"
          placeholder="Remarks"
          rows={4}
        />

        <Divider className="mt-0 mb-2" />

        {isView && experimentStatus !== STATUS.draft && (
          <Row gutter={10} className="flex gap-3 md:gap-0">
            <Col span={24} lg={{ span: 8 }}>
              {/* <FormInput
                name="end_time"
                label={'Experiment End Date & Time'}
                defaultStyle
                placeholder="20/06/2024 & 09:00 PM"
                type="date"
                disabled
              /> */}
              <FormInput
                showTime
                disabled
                name="end_date"
                label={
                  <span className="text-2xs md:text-xs xl:text-sm">
                    Experiment End Date & Time
                  </span>
                }
                placeholder={'NA'}
                type="date"
                defaultStyle
                inputClasses="p-select font-open-sans shadow-none border disabled:!text-secondary-black focus-within:border-primary-dark !text-sm hover:border-tertiary-highlight-200"
              />
            </Col>
            {experimentDetails?.authors?.length &&
              experimentStatus !== STATUS.draft && (
                <Col span={24} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="flex flex-col justify-center gap-2">
                    <Label label={'Done by'} />
                    <div className="flex items-center justify-start mt-1">
                      {experimentDetails?.authors.map((author: any) => {
                        return (
                          <Tag
                            key={author.id}
                            color="blue"
                            className="p-2 font-open-sans"
                          >
                            {author.display_name}
                          </Tag>
                        );
                      })}
                    </div>
                  </div>
                </Col>
              )}
            {experimentStatus === STATUS.approved && (
              <Col span={24} md={{ span: 12 }} lg={{ span: 8 }}>
                <div className="flex flex-col justify-center gap-2">
                  <Label label={'Approved by'} />
                  <div className="flex items-center justify-start mt-1">
                    {/* {experimentDetails?.experiment_approvers?.length &&
                      experimentDetails.experiment_approvers.map(
                        (approver: any) => {
                          return (
                            <Tag key={approver.id} color="cyan" className="p-2">
                              {approver.user.display_name}
                            </Tag>
                          );
                        }
                      )} */}
                    {experimentDetails?.experiment_approvers?.length && (
                      <Tag
                        key={experimentDetails.experiment_approvers?.at(-1)?.id}
                        color="cyan"
                        className="p-2 font-open-sans"
                      >
                        {
                          experimentDetails.experiment_approvers?.at(-1)?.user
                            ?.display_name
                        }
                      </Tag>
                    )}
                  </div>
                </div>
              </Col>
            )}
          </Row>
        )}
        {!children ? '' : children}

        {/*change remarks modal for adding a product */}
        <RemarksModal
          isOpen={isRemarksModalOpen}
          onApprove={(value) =>
            isUpdateComments ? handleUpdateComments(value) : handleSubmit(value)
          }
          loading={addProductLoading || createExperimentLoading}
          onClose={() => {
            setIsRemarksModalOpen(false);
            setIsUpdateComments(false);
          }}
        />
      </div>
    </Card>
  );
}
