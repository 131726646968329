import { useCallback, useEffect } from 'react';
import { Flex, Button, TabsProps, Tabs } from 'antd';
import { useState } from 'react';
import SearchBar from 'common/Content/searchBar';
import { PlusOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ListView from './components/ListView';
import TypeOfTestModal from './components/TypeOfTestModal';
import { getTypeOfList } from 'features/Admin/redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import { getActiveTab } from 'utilities/helpers';

interface payload {
  page: number;
  perPage: number;
  search: string;
  type: string;
}

const PER_PAGE_ITEM = 15;

export default function TypeOfTest() {
  const navigate = useNavigate();
  const [searchClient, setSearchClient] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);
  const [currentTab, setCurrentTab] = useState('0');

  const dispatch: AppDispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { pagination, typeOfList, typeOfListLoading, createTypeOfListLoading } = useSelector(
    (state: RootState) => state.admin
  );

  useEffect(() => {
    handelgetTypeOfList();
  }, [searchClient, currentPage, currentTab]);

  const handelgetTypeOfList = () => {
    const payload: payload = {
      page: currentPage,
      perPage: PER_PAGE_ITEM,
      search: searchClient,
      type: getActiveTab(currentTab),
    };
    dispatch(getTypeOfList(payload));
  };

  const handleInputChange = useCallback(
    debounce((value) => {
      setSearchClient(value);
    }, 500),
    []
  );
  const handleSearchChange = (value: string) => {
    handleInputChange(value);
  };

  const items: TabsProps['items'] = [
    {
      key: '0',
      label: 'Active',
      children: (
        <ListView
          searchClient={searchClient}
          typeOfList={typeOfList}
          handelgetTypeOfList={handelgetTypeOfList}
          typeOfListLoading={typeOfListLoading}
          pagination={pagination}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          PER_PAGE_ITEM={PER_PAGE_ITEM}
          checkedTab={'active'}
          createTypeOfListLoading={createTypeOfListLoading}
        />
      ),
    },
    {
      key: '1',
      label: 'In Active',
      children: (
        <ListView
          searchClient={searchClient}
          typeOfList={typeOfList}
          handelgetTypeOfList={handelgetTypeOfList}
          typeOfListLoading={typeOfListLoading}
          pagination={pagination}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          PER_PAGE_ITEM={PER_PAGE_ITEM}
          checkedTab={'inActive'}
          createTypeOfListLoading={createTypeOfListLoading}
        />
      ),
    },
  ];

  return (
    <Flex vertical className="h-full shadow-equal-sides rounded-xl">
      <Flex
        justify="space-between"
        align="center"
        className="px-6 py-4 rounded-t-xl bg-secondary-filter font-open-sans"
      >
        <h2 className="text-lg font-bold md:text-lg lg:text-[1.25rem] md text-primary-dark">
          Type of Test
        </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <SearchBar
            justify="start"
            align="middle"
            gutter={32}
            inputProps={{
              placeholder: 'Search by type of test',
            }}
            filters={false}
            onChange={(e: any) => handleSearchChange(e.target.value)}
          />
          <Button
            size="middle"
            icon={<PlusOutlined />}
            className="text-primary bg-white font-open-sans hover:bg-primary-dark hover:!text-white font-semibold border border-primary-dark"
            onClick={handleModalOpen}
          >
            Add type of test
          </Button>
        </div>
      </Flex>

      <Flex className="relative h-full mx-2 mb-2 overflow-auto sticky-footer">
        <Tabs
          size="small"
          className="p-0 m-0 bg-white font-open-sans"
          tabBarStyle={{ margin: 0, padding: 0 }}
          defaultActiveKey="0"
          items={items}
          onChange={(tab: any) => {
            setCurrentTab(tab);
          }}
        />
      </Flex>
      <TypeOfTestModal
        addUserLoading={createTypeOfListLoading}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        handelgetTypeOfList={handelgetTypeOfList}
      />
    </Flex>
  );
}
