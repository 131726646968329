import { Button, Col, FormInstance, Row } from 'antd';
import { AppDispatch, RootState } from 'app/store';
import Card from 'common/Card';
import FormInput from 'common/FormInput';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUomList } from '../redux/slice';
import { debounce } from 'lodash';
import { useResponsiveness } from 'hooks/useResponsiveness';
import Editor from 'common/ReactQuill';

type TProps = {
  editorValue: string;
  editorSetValue: React.Dispatch<React.SetStateAction<string>>;
  children?: string | JSX.Element;
  form: FormInstance<any>;
  // aliasCode: null | string;
  // setAliasCode: React.Dispatch<React.SetStateAction<string | null>>;
};

export default function CompoundDetails({
  editorSetValue,
  editorValue,
  children,
  form,
  // aliasCode,
  // setAliasCode,
}: TProps) {
  const dispatch: AppDispatch = useDispatch();
  const { uomList } = useSelector((state: RootState) => state.project2);
  const [searchTerm, setSearchTerm] = useState('');
  const { xs, sm } = useResponsiveness();

  const renderedUOMList = uomList?.map((uom) => {
    return { label: uom.uom_code, value: uom.id };
  });

  useEffect(() => {
    const payloadUOM = {
      search: searchTerm,
    };
    dispatch(getUomList(payloadUOM));
  }, [searchTerm]);

  const handleUomSearch = useCallback(
    debounce((val: string) => {
      setSearchTerm(val);
    }, 500),
    []
  );

  const onUomSearch = (val: string) => {
    handleUomSearch(val);
  };

  return (
    <Card>
      <>
        <Row gutter={20}>
          <Col span={24} md={{ span: 8 }}>
            <FormInput
              name="compound_code"
              label="Compound Code"
              defaultStyle={true}
              rules={[
                { required: true, message: 'Compound code is required' },
                {
                  pattern: /^[A-Za-z]{3}$/,
                  message: 'Compound code must be three alphabetic letters.',
                },
              ]}
              placeholder="Enter compound code"
              inputMaxLength={3}
              inputMinLength={3}
            />
          </Col>
          <Col span={24} md={{ span: 8 }}>
            <Row>
              <Col span={18}>
                <FormInput
                  type="number"
                  name="requested_quantity"
                  label="Quantity Requested"
                  inputClasses="p-select font-open-sans shadow-none border focus-within:border-primary-dark hover:border-tertiary-highlight-200 rounded-e-none"
                  defaultStyle={true}
                  placeholder="Enter quantity requested"
                  required
                  validateMsg="Quantity is required"
                />
              </Col>
              <Col span={6}>
                <FormInput
                  popupClassName="select-dropdown"
                  name="uom_master_id"
                  label={<div className="invisible">uom</div>}
                  onSearch={onUomSearch}
                  type="select"
                  options={renderedUOMList}
                  defaultStyle={false}
                  hideColon
                  required
                  validateMsg={
                    <span className="text-xs sm:text-2xs">UOM is required</span>
                  }
                  selectInputClasses="selected-item-transform border-none focus-within:border-none  placeholder:text-primary select-placeholder"
                  placeholder="UOM"
                  wrapperClasses="rounded-e-md !m-0 hover:border-tertiary-highlight-200 border-tertiary-highlight-200 border focus-within:border-primary-dark"
                />
              </Col>
            </Row>
          </Col>
          <Col span={24} md={{ span: 8 }}>
            <FormInput
              name="requested_purity"
              label="Requested Purity (%)"
              defaultStyle={true}
              placeholder="Enter requested purity"
              required
              validateMsg="Purity is required"
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col span={24}>
            <Editor
              label="Key Specifications & Deliverables"
              value={editorValue}
              setValue={editorSetValue}
              placeholder="Enter key specifications & deliverables"
            />
          </Col>
        </Row>
        {!children ? '' : children}
      </>
    </Card>
  );
}
