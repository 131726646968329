import { debounce as lodashDeboucer } from 'lodash';
import LocalStorage from './localStorage';
import { useCallback } from 'react';
import { AppDispatch } from 'app/store';
import { useDispatch } from 'react-redux';
import { getProjectCode } from 'features/proposalLeadFlow/projects/redux/slice';
import { message, Tag } from 'antd';
import {
  ADMIN,
  CHEMIST,
  EXECUTIVE_ACCESS,
  PROJECT_MANAGER,
  SECTION_HEAD,
  TECHNICAL_LEAD,
} from './roleRights';
interface User {
  user_details?: {
    display_name: string;
    email: string;
    first_name: string;
    id: number;
    last_name: string;
    role: string;
    is_tl: boolean;
    is_first_login: boolean;
  };
  loggedIn: boolean;
  role: string;
}

export const getLoggedInUser = (): User => {
  const loginUser = LocalStorage.getItem('newatom_ELN') || '';
  const user = loginUser || { user: null };
  return {
    ...user,
    loggedIn: loginUser !== '',
  };
};

export const getIntitals = (name: string | undefined): string => {
  if (!name) {
    return '';
  }
  return name
    .split(' ')
    .map((word) => word[0].toUpperCase())
    .join('');
};

export const getRoleName = (role: string | undefined): string | undefined => {
  if (role === EXECUTIVE_ACCESS) return 'Executive Access';
  else if (role === ADMIN) return 'Admin';
  else if (role === PROJECT_MANAGER) return 'Project Manager';
  else if (role === SECTION_HEAD) return 'Section Head';
  else if (role === CHEMIST) return 'Chemist';
  else if (role === TECHNICAL_LEAD) return 'Technical Lead';
  else if (role === 'approver') return 'Approver';
};

// generate unique project code key when creating new project
export const generateUniqueProjectCodeKey = (
  selectedOption: string,
  selectedChildOption: string
  // dispatch: AppDispatch
) => {
  let type = '';

  if (selectedOption === '1') {
    if (!selectedChildOption) {
      type = '';
    } else if (selectedChildOption === '1-1') {
      type = 'gmp';
    } else if (selectedChildOption === '1-2') {
      type = 'development';
    }
  } else if (selectedOption === '2') {
    type = 'specialty';
  }

  // const payload = {
  //   type,
  // };

  return type;

  // return dispatch(getProjectCode(payload))
  //   .then((res: any) => {
  //     if (res?.payload?.success) {
  //       message.success('Project code was successfully generated');
  //       return res.payload.project_code;
  //     } else {
  //       return '';
  //     }
  //   })
  //   .catch(() => {
  //     return '';
  //   });
};

export const getSelectedIndustryType = (projectCode: string) => {
  if (!projectCode) return;
  const code = projectCode.substring(0, 2);
  switch (code) {
    case 'GA':
      return { m: '1', c: '1-1' };
    case 'DA':
      return { m: '1', c: '1-2' };
    case 'SA':
      return { m: '2', c: null };
    default:
      return { m: null, c: null };
  }
};

export const generateAliasCode = () => {
  const randomNumber = Math.floor(1000 + Math.random() * 9000);

  return `AC${randomNumber}`;
};

export const TabTitle = (newTitle: string): string => {
  return (document.title = newTitle);
};

export const STATUS = {
  approved: 'approved',
  pending: 'pending',
  draft: 'draft',
  // closed: 'cloased',
  rejected: 'reject',
};

export const getStatusTag = (status: string) => {
  switch (status) {
    case STATUS.approved:
      return (
        <Tag color="green" className="capitalize font-open-sans">
          {status}
        </Tag>
      );
    case STATUS.rejected:
      return (
        <Tag color="red" className="capitalize font-open-sans">
          {'Returned'}
        </Tag>
      );
    case STATUS.pending:
      return (
        <Tag color="blue" className="capitalize font-open-sans">
          Sent for Approval
        </Tag>
      );
    case STATUS.draft:
      return <Tag className="capitalize font-open-sans">In Progress</Tag>;
    default:
      return <Tag className="capitalize font-open-sans">{status}</Tag>;
  }
};

//--Convert the string H2O 50o +- to formatted chemical string H₂O 50° ± ---
// ₀₁₂₃₄₅₆₇₈₉
export const convertTextWithChemicalFormulas = (text: string): string => {
  return text
    ?.split(/([A-Z][a-z]?\d*|\+-|\d+o?C?)/)
    ?.map((part) => {
      if (/^[A-Z][a-z]?\d*$/.test(part)) {
        const [symbol, numbers] = part.match(/([A-Z][a-z]?)(\d*)/)!.slice(1);
        return `${symbol}${
          numbers
            ? numbers
                .split('')
                .map((digit) => `₀₁₂₃₄₅₆₇₈₉`[parseInt(digit)] || digit)
                .join('')
            : ''
        }`;
      } else if (/^\d+o?C?$/.test(part)) {
        const [numbers, degree, celsius] = part
          .match(/(\d+)(o?)(C?)/)!
          .slice(1);
        return `${numbers}${degree ? '°C' : ''}${celsius ? 'C' : ''}`;
      } else if (part === '+-') {
        return '±';
      } else {
        return part; // Return unchanged part
      }
    })
    .join('');
};

//.....Convert the chemical string H₂O 50° ± to simple string H2O 50o +- .........
export const convertChemicalFormulasToSimpleText = (
  formattedText: string
): string => {
  return formattedText
    ?.replace(/₀/g, '0')
    .replace(/₁/g, '1')
    .replace(/₂/g, '2')
    .replace(/₃/g, '3')
    .replace(/₄/g, '4')
    .replace(/₅/g, '5')
    .replace(/₆/g, '6')
    .replace(/₇/g, '7')
    .replace(/₈/g, '8')
    .replace(/₉/g, '9')
    .replace(/°C/g, 'o') // Convert degree symbol back to 'o'
    .replace(/±/g, '+-'); // Convert plus-minus symbol back to '+-'
};

//formating the field name that are coming in audit logs fetch api
export const getFieldNameText = (field: string) => {
  if (!field) {
    return 'NA';
  }
  const isSingleWord = field.trim().split('_').length === 1;

  if (isSingleWord) {
    return field.charAt(0).toUpperCase() + field.substring(1).toLowerCase();
  }

  const fields: { [key: string]: string } = {
    reaction_scheme: 'Reacttion Scheme',
    is_product_formed: 'Required Product Formed',
    objective_of_reaction: 'Objective of Reaction',
    remarks: 'Remarks',
    references: 'Literature References',
    cas_number: 'CAS Number',
    material_name: 'Material Name',
    mol_formula: 'Mol. Formula',
    mol_weight: 'Mol. Wt',
    th_yield: 'Th. Yield',
    wt_wt: 'wt/Wt',
    uom_id: 'UOM',
  };

  const foundField = fields[field];

  if (!foundField) {
    return field
      .split('_')
      .map((f) => f.charAt(0).toUpperCase() + f.substring(1).toLowerCase())
      .join(' ');
  }

  return foundField;
};

export const getFieldformattedText = (field: string) => {
  switch (field?.toString()) {
    case 'true':
    case 'yes':
    case '1':
      return 'Yes';
    case 'false':
    case 'no':
    case '0':
      return 'No';
    case 'undefined':
    case 'null':
      return 'NA';
    default:
      return field ? field : 'NA';
  }
};

export const getCentralLogsTableName = (table: string) => {
  if (!table) return;

  const isSingleWord =
    table.trim().indexOf(' ') === -1 &&
    table.trim().indexOf('_') === -1 &&
    table.trim().length > 0;

  if (isSingleWord) {
    return (
      table.trim().charAt(0).toUpperCase() +
      table.trim().substring(1).toLowerCase()
    );
  } else {
    return table
      .split('_')
      .map((w) => w.charAt(0).toUpperCase() + w.substring(1).toLowerCase())
      .join(' ');
  }
};

//section names to track unsaved
export const SECTION = {
  experimentDetails: 'experiment_details',
  rawMaterials: 'raw_materials',
  procedure: 'procedure',
  analysis: 'analysis',
  comments: 'comments',
};

//procedure types
export const TYPE = {
  reactions: 'reactions',
  work_up: 'work_up',
  isolation: 'isolation',
};

const USER_STATUS = {
  active: 'active',
  inActive: 'in_active',
};

export const getActiveTab = (index: string): string => {
  switch (index) {
    case '0':
      return USER_STATUS.active;
    case '1':
      return USER_STATUS.inActive;
    default:
      return USER_STATUS.active;
  }
};

export const MATERIAL_MASTERS_FIELDS = [
  'material_name',
  'cas_number',
  'mol_formula',
  'mol_weight',
] as const;

export const EXPERIMENT_CATEGORIES = [
  { label: 'Feasibility', value: 'feasibility' },
  { label: 'Optimization', value: 'optimization' },
  // { label: 'Validation', value: 'validation' },
  { label: 'Lab assurance', value: 'lab_assurance' },
  { label: 'Impurity', value: 'impurity' },
  { label: 'Standard', value: 'standard' },
  { label: 'Polymorphism', value: 'polymorphism' },
  { label: 'Reprocess', value: 'reprocess' },
  { label: 'Rework', value: 'rework' },
];
