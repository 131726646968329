import { Button, Col, Flex, Form, Row } from 'antd';
import FormInput from 'common/FormInput';
import { CENTRAL_LOGS } from 'data/CentralLogs';
import { useNavigate } from 'react-router-dom';
import { getCentralLogs } from '../redux/slice';
import { AppDispatch, RootState } from 'app/store';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CentralLogs from '../components/CentralLogs';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { getprojectList_dropdown } from 'features/proposalLeadFlow/dashboard/redux/slice';

const PER_PAGE_ITEM = 15;

export default function AuditTrail() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isProjectsSelected, setIsProjectsSelected] = useState(false);
  const [selectedProjectIds, setSelectedProjectIds] = useState<any>();
  const { projectListDropdownData, getprojectListDropdownDataLoading } =
    useSelector((state: RootState) => state.project);

  const dispatch: AppDispatch = useDispatch();
  const { centralLogsLoading, pagination } = useSelector(
    (state: RootState) => state.reports
  );

  const transformedCentralLogs = CENTRAL_LOGS.map((item) => {
    return { label: item.label, value: item.tableName };
  });

  const renderingProjectList = projectListDropdownData?.length
    ? projectListDropdownData.map((project: any) => {
        return { label: project.project_code, value: project.id };
      })
    : [];

  const handleSearch = (searchTerm: string) => {
    if (searchTerm) {
      const payload = {
        page: 1,
        perPage: 20,
        search: searchTerm,
      };
      dispatch(getprojectList_dropdown(payload));
    } else {
      const payload = {
        page: 1,
        perPage: 20,
      };
      dispatch(getprojectList_dropdown(payload));
    }
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 300), []);

  const onFormValuesChange = (changedVal: any, values: any) => {
    if (changedVal.table) {
      if (values.table === 'projects') {
        handleSearch('');
        setIsProjectsSelected(true);
      } else {
        setIsProjectsSelected(false);
      }
    }

    if (changedVal.project_search) {
      const projectId = values.project_search;
      if (projectId === 'all') {
        setSelectedProjectIds(true);
      } else {
        if (
          Array.isArray(selectedProjectIds) &&
          selectedProjectIds?.includes(projectId)
        ) {
          setSelectedProjectIds((prevIds: number[]) =>
            prevIds.filter((id) => id !== projectId)
          );
        } else {
          if (selectedProjectIds?.length) {
            setSelectedProjectIds([...selectedProjectIds, projectId]);
          } else {
            setSelectedProjectIds([projectId]);
          }
        }
      }
    }
  };

  const handleSearchLogs = (values: any) => {
    const payload = {
      ...values,
      page: currentPage,
      perPage: PER_PAGE_ITEM,
    };
    if (payload.table === 'projects') {
      if (Array.isArray(selectedProjectIds)) {
        payload.projects = JSON.stringify(
          selectedProjectIds.map((id) => ({ project_id: id }))
        );
      } else {
        payload.all = true;
      }
    }
    dispatch(getCentralLogs(payload));
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    if (Object.values(values).every((e) => e)) {
      handleSearchLogs(values);
    }
  }, [currentPage]);

  return (
    <Flex vertical className="h-full bg-white shadow-equal-sides rounded-xl">
      <Flex
        justify="space-between"
        align="center"
        className="px-6 py-4 rounded-t-xl bg-secondary-filter font-open-sans"
      >
        <h2 className="text-lg font-bold md:text-lg lg:text-[1.25rem] md text-primary-dark">
          Audit Trail
        </h2>
      </Flex>

      {/* list of central logs */}
      <Form
        id="date_selector_modal_form"
        layout="vertical"
        onFinish={handleSearchLogs}
        form={form}
        onValuesChange={onFormValuesChange}
        className="overflow-auto"
      >
        <div className="!p-4">
          <Col span={24}>
            <Row gutter={10}>
              <Col span={24} sm={{ span: 12 }}>
                <FormInput
                  marginBottom="medium"
                  name="table"
                  label={
                    <span className="text-tertiary-dark">Report Name</span>
                  }
                  defaultStyle
                  type="select"
                  placeholder="Select report name"
                  required
                  options={transformedCentralLogs}
                  validateMsg="Report name is required"
                />
              </Col>
              {isProjectsSelected ? (
                <Col span={24} sm={{ span: 6 }}>
                  <FormInput
                    label={'Projects'}
                    loading={getprojectListDropdownDataLoading}
                    marginBottom="medium"
                    name="project_search"
                    type="select"
                    options={[
                      { label: 'All', value: 'all' },
                      ...renderingProjectList,
                    ]}
                    onSearch={debouncedSearch}
                    defaultStyle
                    optionRender={(option: any) => {
                      return (
                        <span
                          className={`!text-xs font-open-sans ${Array.isArray(selectedProjectIds) && selectedProjectIds?.includes(option.value) ? 'text-primary-dark' : ''}`}
                        >
                          {option.label}
                        </span>
                      );
                    }}
                    placeholder="Search project"
                  />
                </Col>
              ) : (
                <>
                  <Col span={24} sm={{ span: 6 }}>
                    <FormInput
                      marginBottom="medium"
                      name="from_date"
                      label={<span className="text-tertiary-dark">From</span>}
                      defaultStyle
                      type="date"
                      placeholder="Selct from date"
                      required
                      validateMsg="From date is required"
                      inputClasses="h-10 font-open-sans !shadow-none border disabled:!text-secondary-black focus-within:border-primary-dark !text-sm hover:border-tertiary-highlight-200"
                    />
                  </Col>
                  <Col span={24} sm={{ span: 6 }}>
                    <FormInput
                      marginBottom="medium"
                      name="to_date"
                      type="date"
                      label={<span className="text-tertiary-dark">To</span>}
                      defaultStyle
                      placeholder="Select to date"
                      required
                      validateMsg="To date is required"
                      inputClasses="h-10 font-open-sans !shadow-none border disabled:!text-secondary-black focus-within:border-primary-dark !text-sm hover:border-tertiary-highlight-200"
                    />
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </div>

        <div className="flex justify-center gap-2 mt-2">
          <Button
            className="text-sm font-semibold text-white bg-primary hover:bg-transparent hover:!border-primary hover:!text-primary font-open-sans"
            onClick={() => {
              form.submit();
              setCurrentPage(1);
            }}
            loading={centralLogsLoading}
          >
            View
          </Button>
          <Button
            className="text-sm font-semibold border font-open-sans text-primary border-primary hover:!text-white hover:!bg-primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
        {/* central logs table */}
        <CentralLogs
          PER_PAGE_ITEM={PER_PAGE_ITEM}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pagination={pagination}
          isProjectsSelected={isProjectsSelected}
        />
      </Form>
    </Flex>
  );
}
