import { Button, Flex, Form, message, Spin } from 'antd';
import CompoundDetails from '../components/CompoundDetails';
import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { createProjectCompound } from '../redux/slice';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getLoggedInUser } from 'utilities/helpers';
import { PROJECT_MANAGER } from 'utilities/roleRights';
import {
  TabTitle,
  convertTextWithChemicalFormulas,
  convertChemicalFormulasToSimpleText,
} from 'utilities/helpers';
import { TCompoundCode } from '../components/CompoundList';
import { getprojectDetail } from 'features/proposalLeadFlow/dashboard/redux/slice';
import RemarksModal from 'common/RemarksModal';

type Tprops = {
  idToEdit?: number | null;
  setIdToEdit?: React.Dispatch<React.SetStateAction<number | null>>;
  initData?: TCompoundCode | undefined;
};

export default function CreateCompound({
  idToEdit,
  setIdToEdit,
  initData,
}: Tprops) {
  TabTitle('Create Compound | NewAtom ELN');
  const [form] = Form.useForm();
  const [editorValue, setEditorValue] = useState<string>('');
  const [formattedInput, setFormattedInput] = useState<string>(''); // Formatted input state
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const { createProjectCompoundLoading } = useSelector(
    (state: RootState) => state.project2
  );
  const navigate = useNavigate();
  const { role } = getLoggedInUser();
  const dispatch: AppDispatch = useDispatch();
  const { id } = useParams();

  if (id === 'undefined') {
    return <Navigate replace to={'/dashboard'} />;
  }

  const onClear = (e: any) => {
    setEditorValue('');
  };

  const handleFormValuesChange = (changedVal: any, values: any) => {
    if (changedVal.compound_code) {
      setFormattedInput(changedVal.compound_code);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === ' ') {
      // Check for spacebar key
      form.setFieldsValue({
        compound_code: convertTextWithChemicalFormulas(formattedInput),
      });
    }
  };

  useEffect(() => {
    if (idToEdit && initData) {
      form.setFieldsValue({
        compound_code: convertTextWithChemicalFormulas(initData.compoundCode),
        requested_quantity: initData.quantityRequested,
        requested_purity: initData.purityRequested,
        uom_master_id: initData.uom_master_id,
      });
      setEditorValue(initData.key_specs);
    }
  }, [idToEdit, initData]);

  const finalSubmit = (values: any, remark: string) => {
    dispatch(
      createProjectCompound({
        ...values,
        compound_code: convertChemicalFormulasToSimpleText(
          values.compound_code
        ),
        project_id: id,
        key_specs: editorValue,
        is_active: true,
        id: idToEdit,
        is_edit: idToEdit ? true : false,
        change_remarks: remark,
      })
    ).then((res: any) => {
      if (res.payload.success) {
        if (idToEdit && setIdToEdit) {
          dispatch(getprojectDetail({ id }));
          setIdToEdit(null);
        }
        if (role === PROJECT_MANAGER) {
          navigate('/projects/' + id, { replace: true });
        } else {
          navigate('/folders/' + id, { replace: true });
        }
      }
    });
  };

  const onSubmit = (values: any, remark: string) => {
    if (!idToEdit) {
      finalSubmit(values, remark);
    } else {
      if (isRemarksModalOpen) {
        setIsRemarksModalOpen(false);
        finalSubmit(values, remark);
      } else {
        setIsRemarksModalOpen(true);
      }
    }
  };
  return (
    <Flex vertical gap="1.25rem">
      {!idToEdit && (
        <h1 className="text-2xl font-bold text-primary font-open-sans">
          Create Compound Code
        </h1>
      )}
      <Form
        onFinish={(values) => onSubmit(values, '')}
        layout="vertical"
        form={form}
        className="flex flex-col gap-5"
        id="compound-form"
        onValuesChange={handleFormValuesChange}
        onKeyDown={handleKeyDown}
      >
        <Spin spinning={createProjectCompoundLoading}>
          <CompoundDetails
            form={form}
            editorSetValue={setEditorValue}
            editorValue={editorValue}
          >
            {/* compound details form */}
            <Flex justify="center" wrap gap={'1rem'} className="mt-4 ">
              <Button
                type="text"
                className="text-primary h-9 font-open-sans"
                onClick={() => {
                  if (idToEdit && setIdToEdit) {
                    setIdToEdit(null);
                  } else {
                    navigate(-1);
                  }
                }}
              >
                Cancel
              </Button>
              {!idToEdit && (
                <Button
                  htmlType="reset"
                  onClick={onClear}
                  className="font-open-sans h-9 hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
                  disabled={createProjectCompoundLoading}
                >
                  Clear
                </Button>
              )}
              <Button
                htmlType="submit"
                type="primary"
                loading={createProjectCompoundLoading}
                // className="text-white bg-primary"
                className="text-white h-9 bg-primary-dark font-open-sans hover:!bg-white hover:!text-primary font-semibold border border-primary-dark"
              >
                {idToEdit ? 'Update' : 'Create'} Compound Code
              </Button>

              {/*change remarks modal */}
              <RemarksModal
                isOpen={isRemarksModalOpen}
                onApprove={(value) => {
                  const values = form.getFieldsValue();
                  onSubmit(values, value);
                }}
                loading={createProjectCompoundLoading}
                onClose={() => setIsRemarksModalOpen(false)}
              />
            </Flex>
          </CompoundDetails>
        </Spin>
      </Form>
    </Flex>
  );
}
