import {
  Badge,
  Button,
  Empty,
  message,
  Popover,
  Space,
  Spin,
  Tag,
  Timeline,
  Tooltip,
} from 'antd';
import Table from 'common/Table';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  CloseOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  FileSearchOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { TProps } from '../index';
import CreateButton from 'common/CreateButton';
import {
  EXPERIMENT_CATEGORIES,
  getLoggedInUser,
  getStatusTag,
  STATUS,
} from 'utilities/helpers';
import { PERMISSIONS } from 'utilities/roleRights';
import { getExperimentTimeline, resetExperimentState } from '../redux/slice';
import { AppDispatch, RootState } from 'app/store';
import { useDispatch } from 'react-redux';
import { apiUrl, getLocalToken } from 'utilities/apiClient';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  convertTextWithChemicalFormulas,
  convertChemicalFormulasToSimpleText,
} from 'utilities/helpers';
import ColumnDropdownFilter from 'common/ColumnDropdownFilter';

const PER_PAGE_ITEM = 15;

export default function ExperimentList({
  currentPage,
  setCurrentPage,
  folderList,
  pagination,
  loading,
  setFromDate,
  setToDate,
}: TProps) {
  const { role } = getLoggedInUser();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { folder_id, project_id } = useParams();
  const [downloading, setDownloading] = useState<number | null>(null);
  const [logId, setLogId] = useState(null);
  const { experimentTimelineData, experimentTimelineLoading } = useSelector(
    (state: RootState) => state.experiments
  );

  //rendering status logs
  const renderedStatusLogs = () => {
    const transformedTimelineData = experimentTimelineData.map(
      (timeline: any) => {
        let status =
          timeline.status[0].toUpperCase() + timeline.status.slice(1);
        const date = moment(timeline.date).format('DD MMM YYYY hh:mm A');
        const name = timeline.user?.display_name;
        let color = 'blue';

        if (timeline.status === STATUS.approved) {
          color = 'green';
        } else if (timeline.status === STATUS.rejected) {
          color = 'red';
          status = 'Returned';
        } else if (timeline.status === 'submitted' && !timeline?.is_edit) {
          color = 'blue';
          status = 'Sent for approval';
        } else if (timeline.status === 'resubmitted' && timeline?.is_edit) {
          color = 'blue';
          status = 'Sent for approval';
        } else if (
          timeline.status === 'submitted' &&
          timeline?.is_edit === true
        ) {
          color = '#1d39c4';
          status = 'Edited';
        } else if (timeline.status === STATUS.draft) {
          color = '#bfbfbf';
          status = 'Saved as Draft';
        } else {
          color = 'blue';
        }

        return {
          color,
          className: 'h-fit',
          children: (
            <p
              className="text-gray-700 font-open-sans"
              onClick={(e) => e.stopPropagation()}
            >{`${status} on ${date} by ${name}`}</p>
          ),
        };
      }
    );
    return transformedTimelineData.length ? (
      <Timeline className="!pt-2" items={transformedTimelineData} />
    ) : (
      <Empty description="No Logs" />
    );
  };

  //get experiment timeline
  useEffect(() => {
    if (logId) {
      dispatch(getExperimentTimeline({ id: logId }));
    }
  }, [logId]);

  //download experiment word file
  const handleExperimentDownload = async (id: number) => {
    setDownloading(id);
    try {
      const params = new URLSearchParams({ id: String(id) });
      const response = await fetch(
        `${apiUrl}/experiments/download-experiment?${params}`,
        {
          headers: {
            Authorization: getLocalToken(),
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to download experiment...');
      }
      setDownloading(null);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', 'experiment.docx');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setDownloading(null);
      message.error(`${error}`);
    }
  };

  const columns = [
    {
      title: (
        <label className="text-sm font-bold text-ellipsis text-nowrap font-open-sans text-primary-dark">
          Experiment ID
        </label>
      ),
      sorter: (a: any, b: any) => a.experimentId - b.experimentId,
      dataIndex: 'experimentId',
      key: 'experimentId',
      width: '11%',
      render: (text: string, record: any, index: number) => (
        <>
          {record.isOld && (
            <Badge.Ribbon
              placement="start"
              text="3+ days old"
              className={`absolute bottom-0 h-[0.85rem] !py-0 flex items-center !bg-orange-400 orange-ribbon-corner ${index === 0 ? '-top-4' : '-top-5'} !-left-2 text-2xs`}
            ></Badge.Ribbon>
          )}
          <p className="textStyle">{text}</p>{' '}
        </>
      ),
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Category
        </label>
      ),
      sorter: (a: any, b: any) => a.experimentId - b.experimentId,
      dataIndex: 'experiment_category',
      key: 'experiment_category',
      width: '10%',
      render: (text: string, record: any, index: number) => (
        <>
          <p className="textStyle">
            {EXPERIMENT_CATEGORIES.find((item) => item.value === text)?.label}
          </p>
        </>
      ),
    },

    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Step #
        </label>
      ),
      sorter: (a: any, b: any) => {
        const stepA = Number(a.stepNo?.split('-')[1]);
        const stepB = Number(b.stepNo?.split('-')[1]);
        return stepA - stepB;
      },
      dataIndex: 'stepNo',
      key: 'stepNo',
      width: '8%',
      render: (text: string) => (
        <>
          <p className="textStyle">
            {text?.split('-')[0]} {text?.split('-')[1]}
          </p>
        </>
      ),
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Started On
        </label>
      ),
      sorter: (a: any, b: any) => moment(a.startDate).diff(moment(b.startDate)),
      dataIndex: 'startDate',
      key: 'startDate',
      width: '12%',
      render: (text: string) => {
        return (
          <>
            <p className="textStyle !normal-case">
              {text ? moment(text).format('DD-MM-YYYY HH:mm a') : 'NA'}
            </p>
          </>
        );
      },
      filterDropdown: () => (
        <ColumnDropdownFilter
          type="date"
          setFromDate={setFromDate}
          setToDate={setToDate}
        />
      ),
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Ended On
        </label>
      ),
      sorter: (a: any, b: any) => {
        const dateA = moment(a.endDate);
        const dateB = moment(b.endDate);
        if (!dateA.isValid()) return -1;
        if (!dateB.isValid()) return 1;
        return dateA.diff(dateB);
      },
      dataIndex: 'endDate',
      key: 'endDate',
      width: '12%',
      render: (text: string) => {
        return (
          <>
            <p className="textStyle !normal-case">
              {text ? moment(text).format('DD-MM-YYYY HH:mm a') : 'NA'}
            </p>
          </>
        );
      },
      filterDropdown: () => (
        <ColumnDropdownFilter
          type="date"
          setFromDate={setFromDate}
          setToDate={setToDate}
        />
      ),
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Objective
        </label>
      ),
      dataIndex: 'objective_of_reaction',
      key: 'objective_of_reaction',
      width: '12%',
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text} overlayClassName="custom-tooltip">
          <p className="textStyle">
            {text && text !== 'undefined'
              ? convertTextWithChemicalFormulas(text)
              : 'NA'}
          </p>
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Author
        </label>
      ),
      dataIndex: 'author',
      key: 'author',
      width: '8%',
      sorter: (a: any, b: any) => a.author?.localeCompare(b.author),
      render: (text: string, record: any, index: number) => (
        <Tooltip
          title={
            record.authors.length
              ? record.authors
                  .map((author: any) => author.first_name)
                  .join(', ')
              : ''
          }
          overlayClassName="custom-tooltip"
        >
          <p className="textStyle">
            {record.authors.length
              ? record.authors
                  .map((author: any) => author.first_name)
                  .join(', ')
              : ''}
          </p>
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Status
        </label>
      ),
      dataIndex: 'status',
      key: 'status',
      width: '8%',
      render: (text: string) => <>{getStatusTag(text)}</>,
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Action
        </label>
      ),
      dataIndex: 'action',
      key: 'action',
      width: '8%',
      onCell: (record: any) => ({
        onClick: (e: any) => {
          e.stopPropagation();
        },
      }),
      render: (_: any, record: any) => (
        <Space size={1}>
          <Tooltip
            title={'View'}
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          >
            <Button
              onClick={(e: any) => {
                e.stopPropagation();
                dispatch(resetExperimentState());
                navigate(`view/${record.key}`);
              }}
              icon={
                <EyeOutlined className="text-tertiary-filter !bg-transparent !border-none font-sm hover:secondary-highlight-200 w-3" />
              }
              className="!bg-transparent  !border-none hover:opacity-75"
            />
          </Tooltip>
          {/* {record.status !== STATUS.draft && ( */}
          <Tooltip
            title={'Timeline'}
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          >
            <Popover
              trigger={['click']}
              content={renderedStatusLogs}
              title={
                <div
                  className="flex justify-between"
                  onClick={(e) => e.stopPropagation()}
                >
                  <span className=" font-open-sans text-md">Timeline</span>
                  <CloseOutlined
                    className="cursor-pointer hover:opacity-75"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setLogId(null);
                    }}
                  />
                </div>
              }
              placement="topLeft"
              open={logId === record.key}
              overlayClassName="w-fit !h-1/2"
              onOpenChange={(open) =>
                open ? setLogId(record.key) : setLogId(null)
              }
            >
              <Button
                onClick={(e) => e.stopPropagation()}
                loading={experimentTimelineLoading && logId === record.key}
                icon={
                  <FileSearchOutlined className="text-tertiary-filter !bg-transparent !border-none font-sm hover:secondary-highlight-200 w-3" />
                }
                className="!bg-transparent  !border-none hover:opacity-75"
              />
            </Popover>
          </Tooltip>
          {/*   )} */}
          {record.status === STATUS.approved && (
            <Tooltip
              title={'Download'}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            >
              <Button
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleExperimentDownload(record.key);
                }}
                icon={
                  <DownloadOutlined className="text-tertiary-filter !bg-transparent !border-none font-sm hover:secondary-highlight-200 w-3" />
                }
                className="!bg-transparent  !border-none hover:opacity-75"
                loading={downloading === record.key}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      {PERMISSIONS.canCreateExperiment(role) ? (
        <Table
          onRow={(record) => ({
            onClick: (e: any) => {
              e.stopPropagation();
              dispatch(resetExperimentState());
              navigate(`view/${record.key}`);
            },
          })}
          loading={loading}
          totalPages={pagination?.total_pages}
          pagination={true}
          pageSize={PER_PAGE_ITEM}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          columns={columns}
          dataSource={folderList}
          components={{
            body: {
              cell: (props: any) => {
                return (
                  <td
                    {...props}
                    style={{ border: 'none' }}
                    className=" !p-2 !px-2 cursor-pointer"
                  />
                );
              },
            },
            header: {
              cell: (props: any) => {
                return (
                  <th
                    {...props}
                    style={{
                      backgroundColor: '#F9FAFF',
                      border: 'none',
                    }}
                    className=" !px-2"
                  >
                    {props.children}
                  </th>
                );
              },
            },
          }}
          emptySpaceEl={
            <div className="flex items-center justify-center cursor-pointer">
              <CreateButton
                subLabel={
                  <p className="mt-2 text-sm font-semibold text-center font-open-sans">
                    Please create an Experiment
                  </p>
                }
                label={
                  <p className="mt-2 text-base font-bold font-open-sans text-primary-dark">
                    No Experiment Created
                  </p>
                }
                iconClassess="w-12"
                borderLess
                onClick={() => {
                  dispatch(resetExperimentState());
                  navigate('create');
                }}
              />
            </div>
          }
        />
      ) : (
        <Table
          loading={loading}
          onRow={(record) => ({
            onClick: (e: any) => {
              e.stopPropagation();
              dispatch(resetExperimentState());
              navigate(`view/${record.key}`);
            },
          })}
          components={{
            body: {
              cell: (props: any) => {
                return (
                  <td
                    {...props}
                    style={{ border: 'none' }}
                    className=" !p-2 !px-2 cursor-pointer"
                  />
                );
              },
            },
            header: {
              cell: (props: any) => {
                return (
                  <th
                    {...props}
                    style={{
                      backgroundColor: '#F9FAFF',
                      border: 'none',
                    }}
                    className=" !px-2"
                  >
                    {props.children}
                  </th>
                );
              },
            },
          }}
          totalPages={pagination?.total_pages}
          pagination={true}
          pageSize={PER_PAGE_ITEM}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          columns={columns}
          dataSource={folderList}
        />
      )}
    </Spin>
  );
}
