import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  folderDetails,
  folderCreate,
  folderList,
  folderRouteList,
  userList,
  materialGet,
  materialGetInit,
} from './api';

interface ProjectState {
  createFolderLoading: boolean;
  folderList: any[];
  folderListLoading: boolean;
  pagination: any;
  folderRouteList: any[];
  folderRouteListLoading: boolean;
  technicalLeadList: any[];
  technicalLeadListLoading: boolean;
  sectionHeadList: any[];
  sectionHeadListLoading: boolean;
  projectTeamList: any[];
  projectTeamListLoading: boolean;
  folderDetails: { [key: string]: any };
  folderDetailsLoading: boolean;
  getMaterialLoading: boolean;
  materialData: any[];
}

const initialState: ProjectState = {
  createFolderLoading: false,
  folderList: [],
  folderListLoading: false,
  pagination: {},
  folderRouteList: [],
  folderRouteListLoading: false,
  technicalLeadList: [],
  technicalLeadListLoading: false,
  sectionHeadList: [],
  sectionHeadListLoading: false,
  projectTeamList: [],
  projectTeamListLoading: false,
  folderDetails: {},
  folderDetailsLoading: false,
  getMaterialLoading: false,
  materialData: [],
};

const actions = {
  CREATE_FOLDER: 'folder/CREATE_FOLDER',
  FOLDER_LIST: 'folder/FOLDER_LIST',
  FOLDER_ROUTE_LIST: 'folder/FOLDER_ROUTE_LIST',
  TECHNICAL_LEAD_LIST: 'folder/TECHNICAL_LEAD_LIST',
  SECTION_HEAD_LIST: 'folder/SECTION_HEAD_LIST',
  PROJECT_TEAM_LIST: 'folder/PROJECT_TEAM_LIST',
  FOLDER_DETAILS: 'folder/FOLDER_DETAILS',
  MATERIAL: 'folder/MATERIAL',
  MATERIAL_INIT: 'folder/MATERIAL_INIT',
};

export const createFolder = createAsyncThunk(
  actions.CREATE_FOLDER,
  async (payload: Record<string, any>) => {
    const response = await folderCreate(payload);
    return response;
  }
);

export const getFolderList = createAsyncThunk(
  actions.FOLDER_LIST,
  async (payload: Record<string, any>) => {
    const response = await folderList(payload);
    return response;
  }
);

export const getFolderRouteList = createAsyncThunk(
  actions.FOLDER_ROUTE_LIST,
  async (payload: Record<string, any>) => {
    const response = await folderRouteList(payload);
    return response;
  }
);

export const getTechnicalLeadList = createAsyncThunk(
  actions.TECHNICAL_LEAD_LIST,
  async () => {
    const response = await userList({ type: 'chemists', is_tl: true });
    return response;
  }
);

export const getSectionHeadsList = createAsyncThunk(
  actions.SECTION_HEAD_LIST,
  async () => {
    const response = await userList({ type: 'section_heads' });
    return response;
  }
);

export const getProjectTeamList = createAsyncThunk(
  actions.PROJECT_TEAM_LIST,
  async () => {
    const response = await userList({ type: 'chemists' });
    return response;
  }
);

export const getFolderDetails = createAsyncThunk(
  actions.FOLDER_DETAILS,
  async (payload: Record<string, any>) => {
    const response = await folderDetails(payload);
    return response;
  }
);

export const getMaterial = createAsyncThunk(
  actions.MATERIAL,
  async (payload: Record<string, any>) => {
    const response = await materialGet(payload);
    return response;
  }
);

export const getInitMaterial = createAsyncThunk(
  actions.MATERIAL_INIT,
  async (payload: Record<string, any>) => {
    const response = await materialGetInit(payload);
    return response;
  }
);

export const projectSlice = createSlice({
  name: 'sectionHead',
  initialState,
  reducers: {
    resetFolderDropdownSearch(state) {
      state.folderList = [];
    },
  },
  extraReducers: (builder) => {
    // create project
    builder
      .addCase(createFolder.pending, (state) => {
        state.createFolderLoading = true;
      })
      .addCase(createFolder.fulfilled, (state, action) => {
        state.createFolderLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createFolder.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createFolderLoading = false;

        message.error(msg);
      });

    // get folder list
    builder
      .addCase(getFolderList.pending, (state) => {
        state.folderListLoading = true;
      })
      .addCase(getFolderList.fulfilled, (state, action) => {
        state.folderListLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.folderList = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getFolderList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.folderListLoading = false;

        message.error(msg);
      });

    // get folder route list
    builder
      .addCase(getFolderRouteList.pending, (state) => {
        state.folderRouteListLoading = true;
      })
      .addCase(getFolderRouteList.fulfilled, (state, action) => {
        state.folderRouteListLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.folderRouteList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getFolderRouteList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.folderRouteListLoading = false;

        message.error(msg);
      });

    // get techincal lead list
    builder
      .addCase(getTechnicalLeadList.pending, (state) => {
        state.technicalLeadListLoading = true;
      })
      .addCase(getTechnicalLeadList.fulfilled, (state, action) => {
        state.technicalLeadListLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.technicalLeadList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getTechnicalLeadList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.technicalLeadListLoading = false;

        message.error(msg);
      });

    // get sections heads list
    builder
      .addCase(getSectionHeadsList.pending, (state) => {
        state.sectionHeadListLoading = true;
      })
      .addCase(getSectionHeadsList.fulfilled, (state, action) => {
        state.sectionHeadListLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.sectionHeadList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getSectionHeadsList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.sectionHeadListLoading = false;

        message.error(msg);
      });

    // get project team list
    builder
      .addCase(getProjectTeamList.pending, (state) => {
        state.projectTeamListLoading = true;
      })
      .addCase(getProjectTeamList.fulfilled, (state, action) => {
        state.projectTeamListLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.projectTeamList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getProjectTeamList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.projectTeamListLoading = false;

        message.error(msg);
      });

    // get experiment details
    builder
      .addCase(getFolderDetails.pending, (state) => {
        state.folderDetailsLoading = true;
      })
      .addCase(getFolderDetails.fulfilled, (state, action) => {
        state.folderDetailsLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.folderDetails = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getFolderDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.folderDetailsLoading = false;

        message.error(msg);
      });

    // get material
    builder
      .addCase(getMaterial.pending, (state) => {
        state.getMaterialLoading = true;
      })
      .addCase(getMaterial.fulfilled, (state, action) => {
        state.getMaterialLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.materialData = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getMaterial.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialLoading = false;

        message.error(msg);
      });

    // get init material
    builder
      .addCase(getInitMaterial.pending, (state) => {
        state.getMaterialLoading = true;
      })
      .addCase(getInitMaterial.fulfilled, (state, action) => {
        state.getMaterialLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          const updatedData = data.flatMap((item: any) => [
            { id: item.id, material_name: item.material_name },
            { id: item.id, cas_number: item.cas_number },
            { id: item.id, mol_formula: item.mol_formula },
            { id: item.id, mol_weight: item.mol_weight },
          ]);
          state.materialData = updatedData;
        } else {
          message.error(msg);
        }
      })
      .addCase(getInitMaterial.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getMaterialLoading = false;

        message.error(msg);
      });
  },
});

export const { resetFolderDropdownSearch } = projectSlice.actions;

export default projectSlice.reducer;
