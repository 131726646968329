import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  clientCreate,
  clientList,
  dashboardCount,
  roleList,
  uomList,
  userCreate,
  usersList,
  uomCreate,
  typeOfList,
  typeOfListCreate,
  experimentLimit,
  changeExperimentLimit,
  materialList,
  materialCreate,
} from './api';

interface ProjectState {
  usersListLoading: boolean;
  usersList: any[];
  createUserLoading: boolean;
  clientsListLoading: boolean;
  clientsList: any[];
  uomList: any[];
  uomListLoading: boolean;
  createClientLoading: boolean;
  pagination: any;
  dashboardCounts: any;
  dashboardCountsLoading: boolean;
  roleList: any[];
  roleListLoading: boolean;
  createUomLoading: boolean;
  typeOfList: any[];
  typeOfListLoading: boolean;
  createTypeOfListLoading: boolean;
  experimentLimitLoading: boolean;
  experimentLimit: any[];
  updateExperimentLimitLoading: boolean;
  materialList: any[];
  materialListLoading: boolean;
  createMaterialLoading: boolean;
}

const initialState: ProjectState = {
  usersListLoading: false,
  createUserLoading: false,
  usersList: [],
  clientsListLoading: false,
  createClientLoading: false,
  clientsList: [],
  pagination: {},
  dashboardCounts: {},
  dashboardCountsLoading: false,
  roleList: [],
  roleListLoading: false,
  uomList: [],
  uomListLoading: false,
  createUomLoading: false,
  typeOfList: [],
  typeOfListLoading: false,
  createTypeOfListLoading: false,
  experimentLimitLoading: false,
  experimentLimit: [],
  updateExperimentLimitLoading: false,
  materialList: [],
  materialListLoading: false,
  createMaterialLoading: false,
};

const actions = {
  USERS_LIST: 'admin/USERS_LIST',
  CREATE_USER: 'admin/CREATE_USER',
  CLIENTS_LIST: 'admin/CLIENTS_LIST',
  EXPERIMENT_LIMIT: 'admin/EXPERIMENT_LIMIT',
  UPDATE_EXPERIMENT_LIMIT: 'admin/UPDATE_EXPERIMENT_LIMIT',
  UOM_LIST: 'admin/UOM_LIST',
  CREATE_CLIENT: 'admin/CREATE_CLIENT',
  DASHBOARD_COUNT: 'admin/DASHBOARD_COUNT',
  ROLE_LIST: 'admin/ROLE_LIST',
  CREATE_UOM: 'admin/CREATE_UOM',
  CREATE_TYPE_OF_TEST: 'admin/CREATE_TYPE_OF_TEST',
  TYPE_OF_TEST_LIST: 'admin/TYPE_OF_TEST_LIST',
  CREATE_MATERIAL: 'admin/CREATE_MATERIAL',
  MATERIAL_LIST: 'admin/MATERIAL_LIST',
};

export const getUsersList = createAsyncThunk(
  actions.USERS_LIST,
  async (payload: Record<string, any>) => {
    const response = await usersList(payload);
    return response;
  }
);

export const createUser = createAsyncThunk(
  actions.CREATE_USER,
  async (payload: Record<string, any>) => {
    const response = await userCreate(payload);
    return response;
  }
);

export const getClientsList = createAsyncThunk(
  actions.CLIENTS_LIST,
  async (payload: Record<string, any>) => {
    const response = await clientList(payload);
    return response;
  }
);

export const getExperimentLimit = createAsyncThunk(
  actions.EXPERIMENT_LIMIT,
  async (payload: Record<string, any>) => {
    const response = await experimentLimit(payload);
    return response;
  }
);

export const updateExperimentLimit = createAsyncThunk(
  actions.UPDATE_EXPERIMENT_LIMIT,
  async (payload: Record<string, any>) => {
    const response = await changeExperimentLimit(payload);
    return response;
  }
);

export const getUomList = createAsyncThunk(
  actions.UOM_LIST,
  async (payload: Record<string, any>) => {
    const response = await uomList(payload);
    return response;
  }
);

export const createClient = createAsyncThunk(
  actions.CREATE_CLIENT,
  async (payload: Record<string, any>) => {
    const response = await clientCreate(payload);
    return response;
  }
);

export const createUom = createAsyncThunk(
  actions.CREATE_UOM,
  async (payload: Record<string, any>) => {
    const response = await uomCreate(payload);
    return response;
  }
);

export const getTypeOfList = createAsyncThunk(
  actions.TYPE_OF_TEST_LIST,
  async (payload: Record<string, any>) => {
    const response = await typeOfList(payload);
    return response;
  }
);

export const createTypeOfList = createAsyncThunk(
  actions.CREATE_TYPE_OF_TEST,
  async (payload: Record<string, any>) => {
    const response = await typeOfListCreate(payload);
    return response;
  }
);

export const getMaterialList = createAsyncThunk(
  actions.MATERIAL_LIST,
  async (payload: Record<string, any>) => {
    const response = await materialList(payload);
    return response;
  }
);

export const createMaterial = createAsyncThunk(
  actions.CREATE_MATERIAL,
  async (payload: Record<string, any>) => {
    const response = await materialCreate(payload);
    return response;
  }
);

export const getDashboardCounts = createAsyncThunk(
  actions.DASHBOARD_COUNT,
  async (payload: Record<string, any>) => {
    const response = await dashboardCount(payload);
    return response;
  }
);

export const getRoleList = createAsyncThunk(
  actions.ROLE_LIST,
  async (payload: Record<string, any>) => {
    const response = await roleList(payload);
    return response;
  }
);

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get dashboard count
    builder
      .addCase(getDashboardCounts.pending, (state) => {
        state.dashboardCountsLoading = true;
      })
      .addCase(getDashboardCounts.fulfilled, (state, action) => {
        state.dashboardCountsLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.dashboardCounts = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getDashboardCounts.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.dashboardCountsLoading = false;

        message.error(msg);
      });

    //get users list
    builder
      .addCase(getUsersList.pending, (state) => {
        state.usersListLoading = true;
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        state.usersListLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.usersList = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getUsersList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.usersListLoading = false;

        message.error(msg);
      });

    // create user
    builder
      .addCase(createUser.pending, (state) => {
        state.createUserLoading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.createUserLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createUser.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createUserLoading = false;

        message.error(msg);
      });

    //get clients list
    builder
      .addCase(getClientsList.pending, (state) => {
        state.clientsListLoading = true;
      })
      .addCase(getClientsList.fulfilled, (state, action) => {
        state.clientsListLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.clientsList = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getClientsList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.clientsListLoading = false;

        message.error(msg);
      });

    // create client
    builder
      .addCase(createClient.pending, (state) => {
        state.createClientLoading = true;
      })
      .addCase(createClient.fulfilled, (state, action) => {
        state.createClientLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createClient.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createClientLoading = false;

        message.error(msg);
      });

    // create uom
    builder
      .addCase(createUom.pending, (state) => {
        state.createUomLoading = true;
      })
      .addCase(createUom.fulfilled, (state, action) => {
        state.createUomLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createUom.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createUomLoading = false;

        message.error(msg);
      });

    //get role list
    builder
      .addCase(getRoleList.pending, (state) => {
        state.roleListLoading = true;
      })
      .addCase(getRoleList.fulfilled, (state, action) => {
        state.roleListLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.roleList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getRoleList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.roleListLoading = false;

        message.error(msg);
      });

    //get uom list
    builder
      .addCase(getUomList.pending, (state) => {
        state.uomListLoading = true;
      })
      .addCase(getUomList.fulfilled, (state, action) => {
        state.uomListLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.uomList = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getUomList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.uomListLoading = false;

        message.error(msg);
      });

    //get type of test list
    builder
      .addCase(getTypeOfList.pending, (state) => {
        state.typeOfListLoading = true;
      })
      .addCase(getTypeOfList.fulfilled, (state, action) => {
        state.typeOfListLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.typeOfList = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getTypeOfList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.typeOfListLoading = false;

        message.error(msg);
      });

    //create type of test
    builder
      .addCase(createTypeOfList.pending, (state) => {
        state.createTypeOfListLoading = true;
      })
      .addCase(createTypeOfList.fulfilled, (state, action) => {
        state.createTypeOfListLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createTypeOfList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createTypeOfListLoading = false;

        message.error(msg);
      });

    //get material list
    builder
      .addCase(getMaterialList.pending, (state) => {
        state.materialListLoading = true;
      })
      .addCase(getMaterialList.fulfilled, (state, action) => {
        state.materialListLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.materialList = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getMaterialList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.materialListLoading = false;

        message.error(msg);
      });

    //create material
    builder
      .addCase(createMaterial.pending, (state) => {
        state.createMaterialLoading = true;
      })
      .addCase(createMaterial.fulfilled, (state, action) => {
        state.createMaterialLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createMaterial.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createMaterialLoading = false;

        message.error(msg);
      });

    //get experiment limit
    builder
      .addCase(getExperimentLimit.pending, (state) => {
        state.experimentLimitLoading = true;
      })
      .addCase(getExperimentLimit.fulfilled, (state, action) => {
        state.experimentLimitLoading = false;
        const { success, message: msg, data } = action.payload;

        if (success) {
          state.experimentLimit = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getExperimentLimit.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.experimentLimitLoading = false;

        message.error(msg);
      });

    //update experiment limit
    builder
      .addCase(updateExperimentLimit.pending, (state) => {
        state.updateExperimentLimitLoading = true;
      })
      .addCase(updateExperimentLimit.fulfilled, (state, action) => {
        state.updateExperimentLimitLoading = false;
        const { success, message: msg } = action.payload;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(updateExperimentLimit.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.updateExperimentLimitLoading = false;

        message.error(msg);
      });
  },
});

export default adminSlice.reducer;
