import { useCallback, useEffect } from 'react';
import { Flex, Button, Tabs } from 'antd';
import { useState } from 'react';
import SearchBar from 'common/Content/searchBar';
import { PlusOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ListView from './components/ListView';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import { getMaterialList } from 'features/Admin/redux/slice';
import { TabsProps } from 'antd/lib';
import { getActiveTab } from 'utilities/helpers';
import MaterialModal from './components/MaterialModal';

interface payload {
  page: number;
  perPage: number;
  search: string;
  type: string;
}

const PER_PAGE_ITEM = 15;

export default function Materials() {
  const navigate = useNavigate();
  const [searchMaterial, setSearchMaterial] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);
  const [currentTab, setCurrentTab] = useState('0');

  const dispatch: AppDispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { pagination, materialList, materialListLoading, createMaterialLoading } = useSelector(
    (state: RootState) => state.admin
  );

  useEffect(() => {
    handleGetMaterialList();
  }, [searchMaterial, currentPage, currentTab]);

  const handleGetMaterialList = () => {
    const payload: payload = {
      page: currentPage,
      perPage: PER_PAGE_ITEM,
      search: searchMaterial,
      type: getActiveTab(currentTab),
    };
    dispatch(getMaterialList(payload));
  };

  const handleInputChange = useCallback(
    debounce((value) => {
      setSearchMaterial(value);
    }, 500),
    []
  );
  const handleSearchChange = (value: string) => {
    handleInputChange(value);
  };

  const items: TabsProps['items'] = [
    {
      key: '0',
      label: 'Active',
      children: (
        <ListView
          searchMaterial={searchMaterial}
          materialList={materialList}
          handelGetMaterialList={handleGetMaterialList}
          materialListLoading={materialListLoading}
          pagination={pagination}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          PER_PAGE_ITEM={PER_PAGE_ITEM}
          addMaterialLoading={createMaterialLoading}
          type='active'
        />
      ),
    },
    {
      key: '1',
      label: 'In Active',
      children: (
        <ListView
          searchMaterial={searchMaterial}
          materialList={materialList}
          handelGetMaterialList={handleGetMaterialList}
          materialListLoading={materialListLoading}
          pagination={pagination}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          PER_PAGE_ITEM={PER_PAGE_ITEM}
          addMaterialLoading={createMaterialLoading}
          type="in_active"
        />
      ),
    },
  ];

  return (
    <Flex vertical className="h-full shadow-equal-sides rounded-xl">
      <Flex
        justify="space-between"
        align="center"
        className="px-6 py-4 rounded-t-xl bg-secondary-filter font-open-sans"
      >
        <h2 className="text-lg font-bold md:text-lg lg:text-[1.25rem] md text-primary-dark">
          Materials
        </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <SearchBar
            justify="start"
            align="middle"
            gutter={32}
            inputProps={{
              placeholder: 'Search by material name',
            }}
            filters={false}
            onChange={(e: any) => handleSearchChange(e.target.value)}
          />
          <Button
            size="middle"
            icon={<PlusOutlined />}
            className="text-primary bg-white font-open-sans hover:bg-primary-dark hover:!text-white font-semibold border border-primary-dark"
            onClick={handleModalOpen}
          >
            Add Material
          </Button>
        </div>
      </Flex>

      <Flex className="relative h-full mx-2 mb-2 overflow-auto sticky-footer">
        <Tabs
          size="small"
          className="p-0 m-0 bg-white font-open-sans"
          tabBarStyle={{ margin: 0, padding: 0 }}
          defaultActiveKey="0"
          items={items}
          onChange={(tab: any) => {
            setCurrentTab(tab);
          }}
        />
      </Flex>
      <MaterialModal
        addMaterialLoading={createMaterialLoading}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        handleGetMaterialList={handleGetMaterialList}
      />
    </Flex>
  );
}
