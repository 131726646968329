import {
  Button,
  Flex,
  Form,
  notification,
  Spin,
  Select,
  message,
  Breadcrumb,
} from 'antd';
import ExperimentDetails from '../components/ExperimentDetails';
import Analysis from '../components/Analysis';
import Comments from '../components/Comments';
import { AppDispatch, RootState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { getFolderDetails } from 'features/sectionHeadFlow/redux/slice';
import {
  CHEMIST,
  EXECUTIVE_ACCESS,
  PERMISSIONS,
  SECTION_HEAD,
  TECHNICAL_LEAD,
} from 'utilities/roleRights';
import { getLoggedInUser, STATUS } from 'utilities/helpers';
import { openNotification } from 'utilities/notification';
import {
  getAnalysisList,
  getProdecedureList,
  getMaterialsList,
  createExperiment,
  getExperimentsDetails,
  getProductsList,
  setExperimentId,
  resetExperimentState,
  approveRejectExperiment,
  addAdditionalComment,
  setExperimentRoot,
} from '../redux/slice';
import Procedure from '../components/Procedure';
import RawMaterialDetails from '../components/RawMaterials';
import moment from 'moment';
import FormInput from 'common/FormInput';
import Modal from 'common/Modal';
import { ExperimentOutlined, EyeOutlined } from '@ant-design/icons';
import ModalCtx from 'common/ModalCtx';
import {
  convertTextWithChemicalFormulas,
  convertChemicalFormulasToSimpleText,
} from 'utilities/helpers';
import folderIcon from 'assets/icons/new_folder_breadcrumb.svg';
import projectIcon from 'assets/icons/projects_breadcrumb.svg';
import projectActiveIcon from 'assets/icons/projects_breadcrumb_active.svg';
import folderActiveIcon from 'assets/icons/new_folder_breadcrumb_active.svg';
import IconLink from 'common/IconLink';

export default function CreateExperiment() {
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  const { folder_id, project_id, experiment_id } = useParams();
  const isCreate = pathname.split('/').at(-1) === 'create';
  const isView = pathname.split('/').at(-2) === 'view';
  const [isEditing, setIsEditing] = useState(false);
  const [totalSteps, setTotalSteps] = useState<number>(0);
  const [showCommentForm, setShowCommentForm] = useState(true);
  const [stepData, setStepData] = useState({});
  const [refAttachments, setRefAttachments] = useState<File[]>([]);
  const [isAdditionalCommentFilled, setIsAdditionalCommentFilled] =
    useState(false);
  const [selectedStep, setSelectedStep] = useState<number>(0);
  const [stepsNew, setStepsNew] = useState<any[]>([]);
  // const [steps, setSteps] = useState<Step[]>([]);
  const [tabClicked, setTabClicked] = useState(false);
  const [experimentDetailsData, setExperimentDetailsData] = useState<any>({});
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isApprovedModalOpen, setIsApprovedModalOpen] = useState(false);
  const [objective_of_reactionInput, setObjective_of_reactionInput] =
    useState<string>(''); // Formatted input state
  const [experiment_reactionsInput, setExperiment_reactionsInput] =
    useState<string>(''); // Formatted input state
  const [remarksformatted, setRemarksFormatted] = useState<string>(''); // Formatted input state
  const [unsavedSections, setUnsavedSections] = useState<string[]>([]);

  const handleCloseRejectModal = () => setIsRejectModalOpen(false);
  const handleOpenRejectModal = () => setIsRejectModalOpen(true);

  const handleCloseApprovedModal = () => setIsApprovedModalOpen(false);
  const handleOpenApprovedModal = () => setIsApprovedModalOpen(true);

  const dispatch: AppDispatch = useDispatch();
  const { role, user_details } = getLoggedInUser();
  const { folderDetails, folderDetailsLoading } = useSelector(
    (state: RootState) => state.sectionHead
  );
  const {
    procedureList,
    analysisList,
    materialsList,
    experimentDetails,
    experimentDetailsLoading,
    createExperimentLoading,
    productList,
    experimentId,
    experimentRoot,
    approveRejectLoading,
    isExternalChemist,
    hasApproveAccess,
    hasSubmitAccess,
  } = useSelector((state: RootState) => state.experiments);

  const navigate = useNavigate();

  if (folder_id === 'undefined' || experiment_id === 'undefined') {
    return <Navigate to={'/dashboard'} replace />;
  }

  //getting array object id for approve/reject
  const getApproverId = (): number | null => {
    if (role === SECTION_HEAD) {
      return experimentDetails.project?.section_heads?.find((user: any) => {
        return user.section_head_id === user_details?.id && user.approve_access;
      })?.id;
    } else if (role === TECHNICAL_LEAD) {
      return experimentDetails?.folder?.project_chemists?.find(
        (chemist: any) => {
          return (
            chemist.chemist_id === user_details?.id &&
            chemist.approve_access &&
            chemist.is_tl
          );
        }
      )?.id;
    } else {
      return null;
    }
  };

  //checking if user has access to the experiment, if not redirecting to dashboard
  // useEffect(() => {
  //   if (
  //     user_details &&
  //     experimentDetails &&
  //     experimentDetails.folder?.project_chemists?.some(
  //       (user: any) => user.chemist_id === user_details?.id
  //     ) === false &&
  //     experimentDetails.project?.section_heads?.some(
  //       (user: any) => user.section_head_id === user_details?.id
  //     ) === false &&
  //     experimentDetails.authors?.some(
  //       (user: any) => user.id === user_details?.id
  //     ) === false &&
  //     role !== EXECUTIVE_ACCESS
  //   ) {
  //     navigate('/', { replace: true });
  //   }
  // }, [experimentDetails]);

  // getting experiment id from params
  useEffect(() => {
    if (isView && !experimentId) {
      dispatch(setExperimentId(experiment_id));
    }
  }, [experiment_id, experimentId]);

  useEffect(() => {
    if (isCreate) {
      if (folderDetails?.folder_route_schemes) {
        setStepsNew(folderDetails.folder_route_schemes);
      }
    } else {
      const folder_route_schemes = folderDetails?.folder_route_schemes?.map(
        (item: any) => {
          return {
            ...item,
            smiles_string: experimentDetailsData?.reaction_scheme,
            reaction_scheme: experimentDetailsData?.reaction_scheme,
            step_number: experimentDetailsData?.step_number,
          };
        }
      );

      setStepsNew(folder_route_schemes);
    }
  }, [folderDetails, experimentDetailsData]);

  const handleFormValuesChange = (changedVal: any, values: any) => {
    const data = folderDetails;
    if (changedVal.remarks) {
      setRemarksFormatted(changedVal.remarks);
      // form.setFieldsValue({
      //   remarks: convertTextWithChemicalFormulas(changedVal.remarks),
      // });
    }
    if (changedVal.objective_of_reaction) {
      setObjective_of_reactionInput(changedVal.objective_of_reaction);
      // form.setFieldsValue({
      //   compound_code: convertTextWithChemicalFormulas(
      //     changedVal.compound_code
      //   ),
      // });
    }
    // if (changedVal.experiment_reactions) {
    //   // setExperiment_reactionsInput(changedVal.experiment_reactions);
    //   form.setFieldsValue({
    //     experiment_reactions: convertTextWithChemicalFormulas(
    //       changedVal.experiment_reactions
    //     ),
    //   });
    // }
    // if (changedVal.observation_reactions) {
    //   form.setFieldsValue({
    //     observation_reactions: convertTextWithChemicalFormulas(
    //       changedVal.observation_reactions
    //     ),
    //   });
    // }
    // if (changedVal.experiment_work_up) {
    //   form.setFieldsValue({
    //     experiment_work_up: convertTextWithChemicalFormulas(
    //       changedVal.experiment_work_up
    //     ),
    //   });
    // }
    // if (changedVal.observation_work_up) {
    //   form.setFieldsValue({
    //     observation_work_up: convertTextWithChemicalFormulas(
    //       changedVal.observation_work_up
    //     ),
    //   });
    // }
    // if (changedVal.experiment_isolation) {
    //   form.setFieldsValue({
    //     experiment_isolation: convertTextWithChemicalFormulas(
    //       changedVal.experiment_isolation
    //     ),
    //   });
    // }
    // if (changedVal.observation_isolation) {
    //   form.setFieldsValue({
    //     observation_isolation: convertTextWithChemicalFormulas(
    //       changedVal.observation_isolation
    //     ),
    //   });
    // }

    if (changedVal.step_no) {
      const foundStep = data?.folder_route_schemes?.find((step: any) => {
        return step.id === changedVal.step_no;
      });

      setStepData(foundStep);

      form.setFieldsValue({
        title: foundStep.description,
        reaction_scheme: foundStep.reaction_scheme,
      });
    }

    if (changedVal.additional_comment) {
      setIsAdditionalCommentFilled(true);
    } else {
      setIsAdditionalCommentFilled(false);
    }

    if (changedVal.product_formed === 1) {
      setShowCommentForm(true);
    }

    if (changedVal.product_formed === 0) {
      setShowCommentForm(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const values = form.getFieldsValue();
    if (e.key === ' ') {
      // Check for spacebar key
      form.setFieldsValue({
        objective_of_reaction: convertTextWithChemicalFormulas(
          objective_of_reactionInput
            ? objective_of_reactionInput
            : experimentDetails?.objective_of_reaction
        ),
        remarks: convertTextWithChemicalFormulas(
          remarksformatted ? remarksformatted : experimentDetails?.remarks
        ),
        experiment_reactions: convertTextWithChemicalFormulas(
          values.experiment_reactions
        ),
        observation_reactions: convertTextWithChemicalFormulas(
          values.observation_reactions
        ),
        experiment_work_up: convertTextWithChemicalFormulas(
          values.experiment_work_up
        ),
        observation_work_up: convertTextWithChemicalFormulas(
          values.observation_work_up
        ),
        experiment_isolation: convertTextWithChemicalFormulas(
          values.experiment_isolation
        ),
        observation_isolation: convertTextWithChemicalFormulas(
          values.observation_isolation
        ),
      });
    }
  };

  //handle craete experiment in create
  const handleCreateExperimentId = () => {
    form
      .validateFields()
      .then((values) => {
        const payload: any = {
          project_id: folderDetails?.project?.id,
          folder_id: folder_id,
          references: values.references,
          step_number: isView
            ? experimentDetails?.step_number
            : `step-${selectedStep + 1}`,
          folder_route_scheme_id: isView
            ? experimentDetails?.folder_route_scheme_id
            : stepsNew?.find((item) => item.sequence === selectedStep)?.id,
          //folderDetails?.folder_route_schemes[0]?.id,
          objective_of_reaction: convertChemicalFormulasToSimpleText(
            values.objective_of_reaction
          ),
          reaction_scheme: isView
            ? experimentDetails?.reaction_scheme
            : isEditing
              ? stepsNew[0]?.smiles_string
              : stepsNew?.filter((item) => item.sequence === selectedStep)[0]
                  ?.smiles_string,
          experiment_category: values.experiment_category,
          status: STATUS.draft,
        };

        let formdata = new FormData();

        formdata.append('project_id', payload.project_id);
        formdata.append('folder_id', payload.folder_id as string);
        formdata.append('references', payload.references);
        formdata.append('step_number', payload.step_number);
        formdata.append(
          'folder_route_scheme_id',
          payload.folder_route_scheme_id
        );
        formdata.append(
          'objective_of_reaction',
          convertChemicalFormulasToSimpleText(payload.objective_of_reaction)
        );
        formdata.append('experiment_category', payload.experiment_category);
        formdata.append('reaction_scheme', payload.reaction_scheme);
        formdata.append('status', payload.status);

        refAttachments.forEach((file) => {
          formdata.append('attachments', file);
        });

        dispatch(createExperiment(formdata)).then((res: any) => {
          if (res?.payload?.success) {
            const experiment_id = res.payload?.id;
            const experiment_root = res.payload?.experiment_root;
            dispatch(setExperimentId(experiment_id));
            dispatch(setExperimentRoot(experiment_root));
            form.setFieldsValue({ experiment_id: experiment_root });
          }
        });
      })
      .catch(() => {});
  };

  // sending the form as pending or draft
  const handleSubmit = (status: string, isSubmitBtn: boolean) => {
    const values = form.getFieldsValue();
    const payload: any = {
      project_id: folderDetails?.project?.id,
      experiment_id: experimentId,
      folder_id: folder_id,
      references: values.references,
      step_number: isView
        ? experimentDetails?.step_number
        : `step-${selectedStep + 1}`,
      folder_route_scheme_id: isView
        ? experimentDetails?.folder_route_scheme_id
        : stepsNew?.find((item) => item.sequence === selectedStep)?.id,
      //folderDetails?.folder_route_schemes[0]?.id,
      objective_of_reaction: convertChemicalFormulasToSimpleText(
        values.objective_of_reaction
      ),
      is_product_formed: showCommentForm,
      // product_name: !showCommentForm ? values.product_name : '',
      remarks: convertChemicalFormulasToSimpleText(values.remarks),
      reaction_scheme: isView
        ? experimentDetails?.reaction_scheme
        : isEditing
          ? stepsNew[0]?.smiles_string
          : stepsNew?.filter((item) => item.sequence === selectedStep)[0]
              ?.smiles_string,
      status,
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
      experiment_category: values.experiment_category,
      experiment_precautions: values.experiment_precautions,
    };

    if (isSubmitBtn && isView) {
      payload.re_submit = true;
    }

    let formdata = new FormData();

    formdata.append('project_id', payload.project_id);
    formdata.append('experiment_id', payload.experiment_id as string);
    formdata.append('re_submit', payload.re_submit);
    formdata.append('folder_id', payload.folder_id as string);
    formdata.append('references', payload.references);
    formdata.append('is_edit', payload.is_edit ? 'true' : 'false');
    formdata.append('step_number', payload.step_number);
    formdata.append('folder_route_scheme_id', payload.folder_route_scheme_id);
    formdata.append(
      'objective_of_reaction',
      convertChemicalFormulasToSimpleText(payload.objective_of_reaction)
    );
    formdata.append('experiment_category', payload.experiment_category);
    formdata.append(
      'is_product_formed',
      payload.is_product_formed ? 'true' : 'false'
    );
    formdata.append(
      'remarks',
      convertChemicalFormulasToSimpleText(payload.remarks)
    );
    formdata.append('reaction_scheme', payload.reaction_scheme);
    formdata.append('status', payload.status);
    formdata.append('experiment_precautions', payload.experiment_precautions);

    refAttachments.forEach((file) => {
      formdata.append('attachments', file);
    });

    dispatch(createExperiment(formdata)).then((res: any) => {
      if (res?.payload?.success) {
        dispatch(setExperimentId(null));
        navigate(`/experiments/${project_id}/${folder_id}`, { replace: true });
      }
    });
  };

  // submit for approval
  const handleSubmitForApproval = () => {
    if (!experimentId) {
      message.error('Please fill in the mandatory fields');
      return;
    }

    // if (!materialsList.length) {
    //   message.error('Please add at least one material');
    //   return;
    // }

    if (unsavedSections.length) {
      openNotification({
        onCancel: () => null,
        onApprove: () => null,
        title: 'Sections not filled',
        subTitle: (
          <span className="text-sm font-open-sans">
            Some sections are not saved, please save them before saving the
            experiment
          </span>
        ),
        approveBtnClasses:
          'px-10 py-4 rouned-lg font-open-sans font-semibold bg-primary text-white border hover:border-primary hover:!text-primary hover:!bg-transparent',
        cancelBtnClasses: 'px-10 py-4 hidden',
        approveText: 'Ok',
      });
      return;
    } else {
      handleSubmit(STATUS.pending, true);
    }
  };

  //saving form data as draft
  const handleSaveAsDraft = () => {
    if (!experimentId) {
      message.error('Please fill in the mandatory fields');
      return;
    }

    if (unsavedSections.length) {
      openNotification({
        onCancel: () => null,
        onApprove: () => null,
        title: 'Sections not filled',
        subTitle: (
          <span className="text-sm font-open-sans">
            Some sections are not saved, please save them before saving the
            experiment
          </span>
        ),
        approveBtnClasses:
          'px-10 py-4 rouned-lg font-open-sans font-semibold bg-primary text-white border hover:border-primary hover:!text-primary hover:!bg-transparent',
        cancelBtnClasses: 'px-10 py-4 hidden',
        approveText: 'Ok',
      });
      return;
    } else {
      handleSubmit(STATUS.draft, false);
    }
  };

  const handleSmilesChange = (newSmiles: string) => {
    if (isEditing) {
      const updatedSteps = stepsNew.map((step) => ({
        ...step,
        smiles_string: newSmiles,
      }));

      setStepsNew(updatedSteps);
    } else {
      const updatedSteps = stepsNew.map((step) =>
        step.sequence === selectedStep
          ? { ...step, smiles_string: newSmiles }
          : step
      );

      setStepsNew(updatedSteps);
    }
  };

  const handleStepChange = (stepSequence: number) => {
    setSelectedStep(stepSequence);
    setTabClicked(true); // Update state to track step selection
  };

  //.....................add additional comment ...........................
  const handleAddAdditionalComment = () => {
    const additionalComment = form.getFieldValue('additional_comment');

    if (additionalComment) {
      const payload = {
        experiment_id,
        additional_comments: additionalComment,
      };

      dispatch(addAdditionalComment(payload)).then((res: any) => {
        if (res?.payload?.success) {
          setIsAdditionalCommentFilled(false);
          dispatch(getExperimentsDetails({ id: experimentId })).then(
            (res: any) => {
              if (res?.payload.success) {
                setExperimentDetailsData(res?.payload?.data);
                dispatch(getFolderDetails({ id: folder_id }));
              }
            }
          );
        }
      });
    }
  };

  //fetching experiment details
  useEffect(() => {
    if (isCreate) {
      dispatch(getFolderDetails({ id: folder_id }));
    }
    if (isView) {
      dispatch(getExperimentsDetails({ id: experiment_id })).then(
        (res: any) => {
          if (res?.payload.success) {
            setExperimentDetailsData(res?.payload?.data);
            dispatch(getFolderDetails({ id: folder_id }));
          }
        }
      );
    }
  }, [folder_id, experiment_id]);

  //fetching procedure list && analysis list
  useEffect(() => {
    if (folderDetails?.project?.id && (experimentId || experiment_id)) {
      const listPayload = {
        project_id: folderDetails?.project?.id,
        experiment_id: experimentId || experiment_id,
      };
      dispatch(getProdecedureList(listPayload));
      dispatch(getAnalysisList(listPayload));
      dispatch(getMaterialsList(listPayload));
      dispatch(getProductsList(listPayload));
    }
  }, [folderDetails?.project?.id]);

  useEffect(() => {
    const data = isCreate
      ? folderDetails
      : isView
        ? { ...experimentDetails, ...folderDetails }
        : {};

    if (isCreate) {
      form.setFieldsValue({
        project_code: data?.project?.project_code,
        folder_id: data?.folder_route?.route + data?.folder_number,
        step_no: 1,
        title: data?.folder_route_schemes?.at(0)?.description,
        reaction_scheme: data?.folder_route_schemes?.at(0)?.reaction_scheme,
      });
      setShowCommentForm(true);
    } else if (isView) {
      form.setFieldsValue({
        project_code: data?.project?.project_code,
        folder_id: data?.folder_route?.route + data?.folder_number,
        experiment_id: data?.experiment_root,
        step_no: experimentDetailsData?.step_number,
        title: data?.folder_route_schemes?.at(0)?.description,
        reaction_scheme: data?.reaction_scheme,
        references:
          data?.references && data?.references !== 'undefined'
            ? data?.references
            : '',
        objective_of_reaction:
          data?.objective_of_reaction &&
          data?.objective_of_reaction !== 'undefined'
            ? convertTextWithChemicalFormulas(data?.objective_of_reaction)
            : '',
        date_time: moment(data?.start_date),
        end_date: moment(data?.end_date),
        remarks:
          data?.remarks && data?.remarks !== 'undefined'
            ? convertTextWithChemicalFormulas(data?.remarks)
            : '',
        additional_comment: data?.additional_comments,
        experiment_category: data?.experiment_category,
        return_remarks:
          data?.experiment_approvers?.at(-1)?.remarks &&
          data?.experiment_approvers?.at(-1)?.remarks !== 'undefined'
            ? data?.experiment_approvers?.at(-1)?.remarks
            : '',
        experiment_precautions: data?.experiment_precautions,
      });
      setShowCommentForm(data?.is_product_formed);
      setSelectedStep(Number(data?.step_number?.split('-')?.at(1)));
    }
  }, [folderDetails, experimentDetails]);

  const mappedSteps = folderDetails?.folder_route_schemes?.length
    ? folderDetails?.folder_route_schemes.map((data: any, i: number) => {
        return { label: i + 1, value: data.id };
      })
    : [];

  const handleApproveOrRejectExperiment = (values: any, status: string) => {
    if (hasApproveAccess) {
      const payload = {
        experiment_id: experimentId || experiment_id,
        id: getApproverId(),
        project_id,
        folder_id,
        status,
        remarks: values.reject_remarks
          ? values.reject_remarks
          : values.approved_remarks
            ? values.approved_remarks
            : '',
      };
      // const foundApproverTL = experimentDetails?.folder?.project_chemists?.find(
      //   (chemist: any) => {
      //     return (
      //       creatorId !== user_details?.id &&
      //       chemist.approve_access &&
      //       chemist.is_tl
      //     );
      //   }
      // );
      // const foundApproverSH = experimentDetails.project?.section_heads?.find(
      //   (chemist: any) => {
      //     return creatorId !== user_details?.id && chemist.approve_access;
      //   }
      // );
      dispatch(
        approveRejectExperiment({ approve_reject_action: [{ ...payload }] })
      ).then((res: any) => {
        if (res?.payload?.success) {
          handleCloseApprovedModal();
          handleCloseRejectModal();
          navigate(`/experiments/${project_id}/${folder_id}`, {
            replace: true,
          });
        }
      });
    }
  };

  return (
    <Spin
      spinning={
        folderDetailsLoading ||
        experimentDetailsLoading ||
        createExperimentLoading
      }
    >
      <Flex vertical gap="1.25rem">
        <div className="font-semibold text-tertiary-dark font-open-sans">
          <Breadcrumb
            separator=">"
            items={[
              {
                title: (
                  <IconLink
                    classes="w-[16px]"
                    activeIcon={projectActiveIcon}
                    icon={projectIcon}
                    path={`/projects/${project_id}`}
                    label={folderDetails?.project?.project_code}
                  />
                ),
              },
              {
                title: (
                  <IconLink
                    classes="w-[12px]"
                    activeIcon={folderActiveIcon}
                    icon={folderIcon}
                    path={`/folders/edit/folder/${project_id}/${folder_id}`}
                    label={
                      folderDetails?.folder_route?.route +
                      folderDetails?.folder_number
                    }
                  />
                ),
              },
              {
                title: (
                  <span className="flex items-center gap-1 text-[1.12rem] font-open-sans text-primary">
                    <ExperimentOutlined className="!text-[1.12rem]" />
                    {experimentId ? (
                      experimentDetails?.experiment_root || experimentRoot
                    ) : (
                      <p className="invisible">e</p>
                    )}
                  </span>
                ),
              },
            ]}
          />
        </div>
        {/* <h1 className="flex items-center gap-1 text-base font-semibold text-tertiary-dark font-open-sans">
          <Link to={`/folders/${project_id}`} className=" hover:!text-primary">
            {folderDetails?.project?.project_code}
          </Link>
          <span>/</span>
          <Link
            to={`/experiments/${project_id}/${folder_id}`}
            className=" hover:!text-primary"
          >
            {folderDetails?.folder_route?.route + folderDetails?.folder_number}
          </Link>
          <span>/</span>
          <span className="cursor-not-allowed ">
            {experimentId ? experimentId : ''}
          </span>
        </h1> */}
        <Form
          id="main_form"
          onFinish={handleSubmitForApproval}
          onValuesChange={handleFormValuesChange}
          onKeyDown={handleKeyDown}
          layout="vertical"
          form={form}
          className="flex flex-col gap-5"
        >
          {/* experiment details */}
          <ExperimentDetails
            experimentId={experimentId}
            steps={mappedSteps}
            setFiles={setRefAttachments}
            files={refAttachments}
            isCreate={isCreate}
            isView={isView}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            stepData={stepData}
            folderDetails={folderDetails}
            folderId={folder_id}
            projectId={folderDetails?.project?.id}
            handleSmilesChange={handleSmilesChange} // Pass the callback
            setStepsNew={setStepsNew}
            stepsNew={stepsNew}
            selectedStep={selectedStep}
            setSelectedStep={setSelectedStep}
            tabClicked={tabClicked}
            setTabClicked={setTabClicked}
            handleStepChange={handleStepChange}
            experimentDetailsData={experimentDetailsData}
            form={form}
            createExperimentLoading={createExperimentLoading}
            setExperimentDetailsData={setExperimentDetailsData}
            experimentStatus={experimentDetails?.status}
            experimentDetails={experimentDetails}
            unsavedSections={unsavedSections}
            setUnsavedSections={setUnsavedSections}
          />

          {!experimentId && (
            <Button
              size="small"
              type="text"
              className="font-open-sans !mx-auto h-9 !bg-primary text-white border border-primary hover:!text-primary hover:!bg-transparent rounded-md w-fit"
              onClick={handleCreateExperimentId}
              loading={createExperimentLoading}
            >
              Create Experiment
            </Button>
          )}

          {/* raw material details */}
          {experimentId && (
            <RawMaterialDetails
              parentForm={form}
              projectId={folderDetails?.project?.id}
              folderId={folder_id}
              materialsList={materialsList}
              isCreate={isCreate}
              isView={isView}
              experimentStatus={experimentDetails?.status}
              folderDetails={folderDetails}
              unsavedSections={unsavedSections}
              setUnsavedSections={setUnsavedSections}
            />
          )}

          {/* procedure details */}
          {experimentId && (
            <Procedure
              experimentId={experimentId}
              folderId={folder_id}
              form={form}
              projectId={folderDetails?.project?.id}
              procedureList={procedureList}
              setStepNos={setTotalSteps}
              isCreate={isCreate}
              isView={isView}
              experimentStatus={experimentDetails?.status}
              unsavedSections={unsavedSections}
              setUnsavedSections={setUnsavedSections}
            />
          )}

          {/* Analysis */}
          {experimentId && (
            <Analysis
              experimentId={experimentId}
              folderId={folder_id}
              parentForm={form}
              projectId={folderDetails?.project?.id}
              totalSteps={totalSteps}
              analysisList={analysisList}
              isCreate={isCreate}
              isView={isView}
              experimentStatus={experimentDetails?.status}
              unsavedSections={unsavedSections}
              setUnsavedSections={setUnsavedSections}
            />
          )}

          {/* comments */}
          {experimentId && (
            <Comments
              createExperimentLoading={createExperimentLoading}
              experimentId={experimentId}
              folderId={folder_id}
              projectId={folderDetails?.project?.id}
              parentForm={form}
              setShowForm={setShowCommentForm}
              isCreate={isCreate}
              isView={isView}
              showForm={showCommentForm}
              productList={productList}
              experimentStatus={experimentDetails?.status}
              folderDetails={folderDetails}
              unsavedSections={unsavedSections}
              setUnsavedSections={setUnsavedSections}
            >
              <>
                {/* ......................additional comments................. */}
                {isView &&
                  ((PERMISSIONS.canAddAdditionalComment(role) &&
                    !experimentDetails?.authors?.some(
                      (author: any) => author.id === user_details?.id
                    )) ||
                    (PERMISSIONS.canReadAdditionalComment(role) &&
                      Boolean(experimentDetails?.additional_comments))) &&
                  experimentDetails?.status === STATUS.approved && (
                    <div className="relative ">
                      <FormInput
                        name="additional_comment"
                        label={'Post Approval Comments'}
                        defaultStyle
                        type="textarea"
                        placeholder="Additional Comment"
                        rows={4}
                        disabled={Boolean(
                          experimentDetails?.additional_comments
                        )}
                        marginBottom="none"
                      />
                      {experimentDetails?.additional_commented_date ? (
                        <span className="absolute right-0 text-xs font-semibold -bottom-5 font-open-sans">
                          commented at :{' '}
                          {moment(
                            experimentDetails?.additional_commented_date
                          ).format('DD.MM.YYYY hh:mm a')}
                        </span>
                      ) : null}
                    </div>
                  )}

                {/* ........................return remarks..................... */}
                {isView && experimentDetails?.status === STATUS.rejected ? (
                  <div className="relative mb-4">
                    <FormInput
                      name="return_remarks"
                      label={'Return Remarks'}
                      defaultStyle
                      type="textarea"
                      placeholder="Return Remarks"
                      rows={4}
                      disabled={true}
                      marginBottom="none"
                    />
                    {experimentDetails?.experiment_approvers?.at(-1)?.user
                      ?.display_name ? (
                      <span className="absolute right-0 text-xs font-semibold -bottom-5 font-open-sans">
                        returned by :{' '}
                        {
                          experimentDetails?.experiment_approvers[0].user
                            ?.display_name
                        }
                      </span>
                    ) : null}
                  </div>
                ) : null}

                <Flex justify="center" wrap gap={'1rem'} className="mt-4 ">
                  {/*..............................back.............................. */}
                  <Button
                    className="text-sm font-open-sans hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>

                  {/*..............................save as draft.............................. */}
                  {(isCreate && PERMISSIONS.canSubmitExperiment(role)) ||
                  (isView &&
                    (experimentDetails?.status === STATUS.draft ||
                      experimentDetails?.status === STATUS.rejected) &&
                    PERMISSIONS.canSubmitExperiment(role) &&
                    !hasApproveAccess &&
                    hasSubmitAccess) ? (
                    <Button
                      onClick={handleSaveAsDraft}
                      type="primary"
                      className="font-semibold bg-transparent border border-primary text-primary font-open-sans hover:text-white hover:!bg-primary"
                      loading={createExperimentLoading}
                    >
                      Save as Draft
                    </Button>
                  ) : null}

                  {/*..............................save.............................. */}
                  {isView &&
                    PERMISSIONS.canAddAdditionalComment(role) &&
                    experimentDetails?.status === STATUS.approved &&
                    isAdditionalCommentFilled && (
                      <Button
                        onClick={() => {
                          openNotification({
                            onApprove: () => handleAddAdditionalComment(),
                            title: 'Save the experiment',
                            subTitle:
                              'Are you sure you want to Post approval comments?',
                            /* 'Are you sure you want to add additional comment?', */
                            approveBtnClasses:
                              'text-white font-open-sans bg-primary px-10 py-4',
                            cancelBtnClasses: 'px-10 py-4',
                            approveText: 'Yes',
                            cancelText: 'Cancel',
                          });
                        }}
                        type="primary"
                        className="font-semibold text-white hover:!bg-transparent bg-primary hover:!border hover:!text-primary hover:!border-primary font-open-sans"
                      >
                        Save
                      </Button>
                    )}

                  {/*..............................submit for approval.............................. */}
                  {(isCreate && PERMISSIONS.canSubmitExperiment(role)) ||
                  (isView &&
                    (experimentDetails?.status === STATUS.draft ||
                      experimentDetails?.status === STATUS.rejected) &&
                    PERMISSIONS.canSubmitExperiment(role) &&
                    !hasApproveAccess &&
                    hasSubmitAccess) ? (
                    <Button
                      onClick={() => {
                        openNotification({
                          onApprove: () => handleSubmitForApproval(),
                          okBtnLoading: createExperimentLoading,
                          title: 'Submit the Experiment for approval',
                          subTitle:
                            'Are you sure you want to submit the experiment for Approval?',
                          approveBtnClasses:
                            'text-white font-open-sans bg-primary px-10 py-4',
                          cancelBtnClasses: 'px-10 py-4',
                          approveText: 'Yes',
                          cancelText: 'Cancel',
                        });
                      }}
                      type="primary"
                      className="font-semibold text-white hover:!bg-transparent bg-primary hover:!border hover:!text-primary hover:!border-primary font-open-sans"
                      loading={createExperimentLoading}
                    >
                      Submit for Approval
                    </Button>
                  ) : null}

                  {/*..............................close experiment.............................. */}
                  {/* {isView &&
                PERMISSIONS.canCloseExperiment(role) &&
                hasApproveAccess && (
                  <Button
                    onClick={() => {
                      openNotification({
                        onApprove: () => console.log('Closed'),
                        title: 'Closing the Experiment',
                        subTitle:
                          'Are you sure you want to close the Experiment',
                        approveBtnClasses:
                          'text-white font-open-sans bg-primary px-10 py-4',
                        cancelBtnClasses: 'px-10 py-4 font-open-sans',
                        approveText: 'Close',
                        cancelText: 'No',
                      });
                    }}
                    type="primary"
                    className="font-semibold text-white bg-primary font-open-sans"
                  >
                    Close Experiment
                  </Button>
                )} */}

                  {/*..............................reject experiment.............................. */}
                  {isView &&
                  PERMISSIONS.canApproveRejectExperiment(role) &&
                  hasApproveAccess &&
                  experimentDetails?.status === STATUS.pending ? (
                    <>
                      <Button
                        onClick={handleOpenRejectModal}
                        className="font-semibold text-red-500 border border-red-500 font-open-sans"
                        loading={approveRejectLoading}
                      >
                        Return
                      </Button>

                      {/*..............................approve experiment.............................. */}
                      <Button
                        type="primary"
                        className="font-semibold text-white bg-green-600 font-open-sans"
                        onClick={handleOpenApprovedModal}
                        loading={approveRejectLoading}
                      >
                        Approve
                      </Button>
                    </>
                  ) : null}
                </Flex>
              </>
            </Comments>
          )}

          {/* hidden fields */}
          <div className="hidden">
            <FormInput name="remarks" />
            <FormInput name="experiment_precautions" />
            {/* <FormInput name="product_name" /> */}
          </div>

          {/* reject modal */}
          <ModalCtx
            onApprove={(values) =>
              handleApproveOrRejectExperiment(values, STATUS.rejected)
            }
            onClose={handleCloseRejectModal}
            isOpen={isRejectModalOpen}
            fieldName="reject_remarks"
            fieldPlaceholder="Enter remark"
            loading={approveRejectLoading}
            headerTitle="Returning the Experiment"
            description={'Are you sure you want to return the Experiment?'}
            agreeBtnClasses="text-white font-open-sans bg-red-600 px-10"
            agreeText="Return"
          />
          {/* approve modal */}
          <ModalCtx
            onApprove={(values) =>
              handleApproveOrRejectExperiment(values, STATUS.approved)
            }
            onClose={handleCloseApprovedModal}
            isOpen={isApprovedModalOpen}
            fieldName="approved_remarks"
            fieldPlaceholder="Enter remark"
            loading={approveRejectLoading}
            headerTitle="Approving the Experiment"
            description={'Are you sure you want to approve the Experiment?'}
            agreeBtnClasses="text-white font-open-sans bg-green-600 px-10"
            agreeText="Approve"
          />
        </Form>
      </Flex>
    </Spin>
  );
}
