import { Empty, Form, FormInstance, Space, Spin, Tooltip } from 'antd';
import Card from 'common/Card';
import Table from 'common/Table';
import Tabs from 'common/Tabs';
import deleteIcon from 'assets/icons/delete.svg';
import editIcon from 'assets/icons/edit.svg';
import RawMaterialTab from './tabs/RawMaterialTab';
import SolventOthersTab from './tabs/SolventOthersTab';
import { AppDispatch, RootState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMaterials,
  createExperiment,
  getExperimentsDetails,
  getMaterialsList,
} from '../../redux/slice';
import { useCallback, useEffect, useState } from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { openNotification } from 'utilities/notification';
import {
  getLoggedInUser,
  MATERIAL_MASTERS_FIELDS,
  SECTION,
} from 'utilities/helpers';
import FormInput from 'common/FormInput';
import SectionHeader from 'common/SectionHeader';
import {
  convertTextWithChemicalFormulas,
  convertChemicalFormulasToSimpleText,
} from 'utilities/helpers';
import RemarksModal from 'common/RemarksModal';
import {
  getFolderDetails,
  getInitMaterial,
  getMaterial,
} from 'features/sectionHeadFlow/redux/slice';
import { debounce } from 'lodash';

const TYPE = {
  rawMaterial: 'raw_materials',
  solvent: 'solvent_others',
};

export const MATERIAL_TYPES = {
  rawMaterials: 'raw_materials',
  solvents: 'solvent_others',
};

type TFormData = {
  material_name: string;
  cas_number: string;
  mol_formula: string;
  mol_weight: number;
  quantity: number;
  uom_master_id: number;
  moles: string;
  equivalents: string;
  source: string;
  wt_wt: string;
};

export type TProps = {
  children?: string | JSX.Element | undefined;
  isCreate: boolean;
  isView: boolean;
  parentForm: FormInstance<any>;
  projectId: string | number;
  folderId: string | undefined;
  materialsList: string[];
  experimentStatus: string;
  folderDetails: any;
  unsavedSections: string[];
  setUnsavedSections: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function RawMaterialDetails({
  children,
  isCreate,
  isView,
  parentForm,
  projectId,
  folderId,
  materialsList,
  experimentStatus,
  folderDetails,
  unsavedSections,
  setUnsavedSections,
}: TProps) {
  const { uomList } = useSelector((state: RootState) => state.project2);
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const [isUpdatePrecautions, setIsUpdatePrecautions] = useState(false);
  const [form] = Form.useForm();
  const [idToRemove, setIdToRemove] = useState<number | null>(null);
  const [idToEdit, setIdToEdit] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const {
    experimentId,
    addMaterialsLoading,
    experimentDetails,
    createExperimentLoading,
    isExternalChemist,
  } = useSelector((state: RootState) => state.experiments);
  const dispatch: AppDispatch = useDispatch();

  const { getMaterialLoading, materialData } = useSelector(
    (state: RootState) => state.sectionHead
  );

  const { role } = getLoggedInUser();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [transformedMaterialNames, setTransformedMaterialNames] = useState<
    any[]
  >([]);
  const [transformedCASNumbers, setTransformedCASNumbers] = useState<any[]>([]);
  const [transformedMolFormulas, setTransformedMolFormulas] = useState<any[]>(
    []
  );
  const [transformedMolWeights, setTransformedMolWeights] = useState<any[]>([]);

  //rendering uom list
  const renderedUOMList = uomList?.map((uom) => {
    return { label: uom.uom_code, value: uom.id };
  });

  //delete material
  const deleteMaterial = (payload: {
    is_active: boolean;
    id: number;
    experiment_id: string | null;
    project_id: string | number;
  }) => {
    dispatch(addMaterials(payload)).then((res: any) => {
      const listPayload = {
        project_id: projectId,
        experiment_id: experimentId,
      };
      if (res?.payload?.success) {
        setIdToRemove(null);
        dispatch(getMaterialsList(listPayload));
      } else {
        setIdToRemove(null);
      }
    });
  };

  //handle click on edit btn in row
  const handleEditClick = (id: number) => {
    setIdToEdit(id);
    const selectedRow: any = materialsList.find(
      (material: any) => material.id === id
    );
    form.setFieldsValue(selectedRow);
    form.setFieldsValue({ uom_master_id: selectedRow?.uom_master?.id });
    form.setFieldsValue({
      mol_formula: convertTextWithChemicalFormulas(selectedRow?.mol_formula),
    });
  };

  //handle update material row
  const handleUpdateMaterialRow = (
    material: any,
    is_first_item: boolean,
    values: any
  ) => {
    setIsRemarksModalOpen(false);
    const payload = {
      ...values,
      id: material?.id,
      is_manual_entry: material?.is_manual_entry,
      material_name: transformedMaterialNames.find(
        (item) => item.value === values.material_name
      )?.label,
      uom_id: values.uom_master_id,
      type: material.type,
      is_first_item,
      experiment_id: experimentId,
      project_id: projectId,
      mol_formula: convertChemicalFormulasToSimpleText(values?.mol_formula),
      change_remarks: values.remark,
      section: 'raw_material_details',
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
    };

    if (material.type === TYPE.rawMaterial) {
      payload.cas_number = transformedCASNumbers.find(
        (item) => item.value === values.cas_number
      )?.label;
    }

    dispatch(addMaterials(payload)).then((res: any) => {
      if (res?.payload?.success) {
        setIdToEdit(null);
        const experiment_id = res?.payload?.experiment_id || experimentId;
        const listPayload = {
          project_id: projectId,
          experiment_id,
        };
        form.resetFields();
        dispatch(getMaterialsList(listPayload));
      }
    });
  };

  const handleEditSubmit = (
    material: any,
    is_first_item: boolean,
    remark: string
  ) => {
    form
      .validateFields()
      .then((values: any) => {
        if (isCreate) {
          handleUpdateMaterialRow(material, is_first_item, {
            remark,
            ...values,
          });
        } else {
          if (isRemarksModalOpen) {
            handleUpdateMaterialRow(material, is_first_item, {
              remark,
              ...values,
            });
          } else {
            setIsRemarksModalOpen(true);
          }
        }
      })
      .catch(() => {});
  };

  //handle material search
  const handleSearch = (searchTerm: string, slug: string) => {
    if (searchTerm) {
      form.resetFields(MATERIAL_MASTERS_FIELDS.filter((val) => val !== slug));
      const payload = {
        material_fields: [
          {
            fields: MATERIAL_MASTERS_FIELDS.map((val) => ({
              field_name: val,
              filter: val === slug ? searchTerm : null,
            })),
          },
        ],
      };
      dispatch(getMaterial(payload));
    }
  };

  const debouncedSearch = useCallback(
    debounce((term: string, slug: string) => handleSearch(term, slug), 500),
    []
  );

  //loading master material in select fields
  useEffect(() => {
    if (materialData && !getMaterialLoading) {
      setTransformedMaterialNames(
        materialData
          .filter((item) => item[MATERIAL_MASTERS_FIELDS[0]])
          .map((item) => ({ value: item.id, label: item.material_name }))
      );

      setTransformedCASNumbers(
        materialData
          .filter((item) => item[MATERIAL_MASTERS_FIELDS[1]])
          .map((item) => ({ value: item.id, label: item.cas_number }))
      );

      setTransformedMolFormulas(
        materialData
          .filter((item) => item[MATERIAL_MASTERS_FIELDS[2]])
          .map((item) => ({ value: item.id, label: item.mol_formula }))
      );

      setTransformedMolWeights(
        materialData
          .filter((item) => item[MATERIAL_MASTERS_FIELDS[3]])
          .map((item) => ({ value: item.id, label: item.mol_weight }))
      );
    }
  }, [materialData]);

  //delete material (checking if its first or not)
  const handleDeleteMaterial = (id: number, is_first: boolean) => {
    const payload = {
      id,
      is_active: false,
      experiment_id: experimentId,
      bulk_delete: is_first ? true : false,
      project_id: projectId,
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
    };

    if (is_first) {
      openNotification({
        onCancel: () => setIdToRemove(null),
        onApprove: () => deleteMaterial(payload),
        okBtnLoading: addMaterialsLoading,
        title: 'Please confirm',
        subTitle: (
          <span className="text-sm font-open-sans">
            This material has dependent entries
          </span>
        ),
        approveBtnClasses:
          'text-white font-open-sans bg-secondary-red px-10 py-4 hover:!text-secondary-red hover:!bg-white !outline-none  border-secondary-red shadow-none active:bg-secondary-red focus:shadow-none focus:border-secondary-red focus:bg-secondary-red',
        cancelBtnClasses: 'px-10 py-4',
        approveText: 'Delete',
        cancelText: 'No',
      });
    } else {
      deleteMaterial(payload);
    }
  };

  const handleFormValuesChange = (changedVal: any, values: any) => {
    if (isFinite(changedVal.material_name)) {
      const selectedRow: any = materialsList.find(
        (material: any) => material.id === idToEdit
      );
      if (selectedRow && selectedRow.type === TYPE.rawMaterial) {
        form.setFieldsValue({
          cas_number: transformedCASNumbers.find(
            (item) => item.value === values.material_name
          )?.value,
          mol_formula: transformedMolFormulas.find(
            (item) => item.value === values.material_name
          )?.label,

          mol_weight: transformedMolWeights.find(
            (item) => item.value === values.material_name
          )?.label,
        });
      }
    }

    if (isFinite(changedVal.cas_number)) {
      form.setFieldsValue({
        material_name: transformedMaterialNames.find(
          (item) => item.value === values.cas_number
        )?.value,
        mol_formula: transformedMolFormulas.find(
          (item) => item.value === values.cas_number
        )?.label,
        mol_weight: transformedMolWeights.find(
          (item) => item.value === values.cas_number
        )?.label,
      });
    }
  };

  const columns = [
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          S.No
        </label>
      ),
      dataIndex: 'index',
      key: 'index',
      width: '4%',
      hidden: idToEdit,
      render: (text: string, record: any, index: number) => (
        <Tooltip title={text} overlayClassName="custom-tooltip">
          <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
            {index + 1}
          </p>
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Material Name
        </label>
      ),
      dataIndex: 'material_name',
      key: 'material_name',
      width: '10%',
      render: (text: string, record: any) =>
        idToEdit === record?.id ? (
          <FormInput
            noStyle
            name="material_name"
            type="select"
            onSearch={(t) => debouncedSearch(t, 'material_name')}
            loading={getMaterialLoading}
            options={transformedMaterialNames}
            optionRender={(option) => <p className="text-xs">{option.label}</p>}
          />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {text}
            </p>
          </Tooltip>
        ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          CAS Number
        </label>
      ),
      dataIndex: 'cas_number',
      key: 'cas_number',
      width: '8%',
      render: (text: string, record: any) =>
        idToEdit === record?.id ? (
          <FormInput
            disabled={record.type === TYPE.solvent}
            noStyle
            name="cas_number"
            type="select"
            onSearch={(t) => debouncedSearch(t, 'cas_number')}
            loading={getMaterialLoading}
            options={transformedCASNumbers}
            optionRender={(option) => <p className="text-xs">{option.label}</p>}
          />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {!text ? 'NA' : text}
            </p>
          </Tooltip>
        ),
      //renderColumn,
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Mol. Formula {isCreate && '(A)'}
        </label>
      ),
      dataIndex: 'mol_formula',
      key: 'mol_formula',
      width: '8%',
      render: (text: string, record: any) =>
        idToEdit === record?.id ? (
          <FormInput disabled noStyle name="mol_formula" />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {!text ? 'NA' : convertTextWithChemicalFormulas(text)}
            </p>
          </Tooltip>
        ),
      //renderColumn,
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Mol. Wt {isCreate && '(B)'}
        </label>
      ),
      dataIndex: 'mol_weight',
      key: 'mol_weight',
      width: '8%',
      ellipsis: true,
      render: (text: string, record: any) =>
        idToEdit === record?.id ? (
          <FormInput disabled type="number" noStyle name="mol_weight" />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {text === null ? 'NA' : parseFloat(text).toFixed(2)}
            </p>
          </Tooltip>
        ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Quantity {isCreate && '(C)'}
        </label>
      ),
      dataIndex: 'quantity',
      key: 'quantity',
      width: '8%',
      render: (text: string, record: any, index: number) => {
        const concatinatedStr = !text
          ? 'NA'
          : !record.uom_master
            ? text + ' ' + 'NA'
            : parseFloat(text).toFixed(2) + ' ' + record.uom_master?.uom_code;
        return idToEdit === record?.id ? (
          <FormInput
            type="number"
            disabled={index === 0 || record.is_manual_entry ? false : true}
            noStyle
            name="quantity"
          />
        ) : (
          <Tooltip overlayClassName="custom-tooltip" title={text}>
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {concatinatedStr}
            </p>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          UOM
        </label>
      ),
      dataIndex: 'uom',
      key: 'uom',
      width: '6%',
      hidden: !idToEdit,
      render: (text: string, record: any) =>
        idToEdit === record.id ? (
          <FormInput
            type="select"
            noStyle
            options={renderedUOMList}
            popupClassName="select-dropdown"
            name="uom_master_id"
            selectInputClasses="selected-item-transform bg-white border border-tertiary-highlight-200 rounded-md placeholder:text-primary select-placeholder"
          />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {
                renderedUOMList.find(
                  (uom) => uom.value === record?.uom_master?.id
                )?.label
              }
            </p>
          </Tooltip>
        ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Moles {isCreate && '(D)'}
        </label>
      ),
      dataIndex: 'moles',
      key: 'moles',
      width: '6%',
      render: (text: string, record: any) =>
        idToEdit === record?.id ? (
          <FormInput
            disabled={!record.is_manual_entry || record.type === TYPE.solvent}
            noStyle
            name="moles"
          />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {text === null ? 'NA' : parseFloat(text).toFixed(4)}
            </p>
          </Tooltip>
        ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Equivalents {isCreate && '(E)'}
        </label>
      ),
      dataIndex: 'equivalents',
      key: 'equivalents',
      width: '8%',
      render: (text: string, record: any) =>
        idToEdit === record?.id ? (
          <FormInput
            disabled={record.type === TYPE.solvent}
            noStyle
            name="equivalents"
          />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {text === null ? 'NA' : parseFloat(text).toFixed(2)}
            </p>
          </Tooltip>
        ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Wt/Wt {isCreate && '(F)'}
        </label>
      ),
      dataIndex: 'wt_wt',
      key: 'wt_wt',
      width: '6%',
      render: (text: string, record: any) =>
        idToEdit === record?.id ? (
          <FormInput
            disabled={record.type === TYPE.rawMaterial}
            type="number"
            noStyle
            name="wt_wt"
          />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {!text ? 'NA' : parseFloat(text).toFixed(2)}
            </p>
          </Tooltip>
        ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Source
        </label>
      ),
      dataIndex: 'source',
      key: 'source',
      width: '7%',
      render: (text: string, record: any) =>
        idToEdit === record?.id ? (
          <FormInput noStyle name="source" />
        ) : (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {text}
            </p>
          </Tooltip>
        ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: idToEdit ? '8%' : '6%',
      hidden: isView && !isEditing,
      render: (text: string, record: any, index: number) => (
        <Space>
          {idToEdit === record.id ? (
            idToEdit === record.id && addMaterialsLoading ? (
              <Spin indicator={<LoadingOutlined spin />} size="small" />
            ) : (
              <>
                <Tooltip title={'Save'} overlayClassName="custom-tooltip">
                  <div className="w-full mx-auto text-center">
                    <CheckOutlined
                      className="w-4 text-green-500 cursor-pointer hover:opacity-75"
                      onClick={() => handleEditSubmit(record, index === 0, '')}
                    />
                  </div>
                </Tooltip>
                <Tooltip title={'Cancel'} overlayClassName="custom-tooltip">
                  <div className="w-full mx-auto text-center">
                    <CloseOutlined
                      className="w-4 text-gray-500 cursor-pointer hover:opacity-75"
                      onClick={() => {
                        setIdToEdit(null);
                      }}
                    />
                  </div>
                </Tooltip>
                {/*change remarks modal */}
                <RemarksModal
                  isOpen={
                    (isRemarksModalOpen && isView) ||
                    (isRemarksModalOpen && isView && isUpdatePrecautions)
                  }
                  onApprove={(value) =>
                    isUpdatePrecautions
                      ? handleUpdateRawMaterialWithPrecautions(value)
                      : handleEditSubmit(record, index === 0, value)
                  }
                  loading={addMaterialsLoading}
                  onClose={() => {
                    setIsRemarksModalOpen(false);
                    setIsUpdatePrecautions(false);
                  }}
                />
              </>
            )
          ) : (
            <Tooltip title={'Edit'} overlayClassName="custom-tooltip">
              <div className="w-full mx-auto text-center">
                <img
                  alt="edit icon"
                  src={editIcon}
                  className="w-4 cursor-pointer hover:opacity-75 hover:invert"
                  onClick={() => {
                    handleEditClick(record.id);
                  }}
                />
              </div>
            </Tooltip>
          )}
          <Tooltip title={'Delete'} overlayClassName="custom-tooltip">
            <div className="w-full mx-auto text-center">
              {(idToEdit === record.id && addMaterialsLoading) ||
              (idToRemove && idToRemove === record.id) ? (
                <Spin indicator={<LoadingOutlined spin />} size="small" />
              ) : (
                <img
                  alt="delete icon"
                  src={deleteIcon}
                  className="cursor-pointer hover:opacity-75"
                  onClick={() => {
                    handleDeleteMaterial(record.id, index === 0);
                    setIdToRemove(record.id);
                  }}
                />
              )}
            </div>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const tabs = [
    {
      id: 1,
      label: 'Raw material details',
      content: (
        <RawMaterialTab
          folderId={folderId}
          parentForm={parentForm}
          isCreate={isCreate}
          isView={isView}
          projectId={projectId}
          listLength={materialsList.length}
        />
      ),
    },
    {
      id: 2,
      label: 'Solvent & Others',
      content: (
        <SolventOthersTab
          folderId={folderId}
          isCreate={isCreate}
          isView={isView}
          projectId={projectId}
        />
      ),
      disabled: !materialsList.length,
    },
  ];

  const updateRawMaterialWithPrecautions = (values: any) => {
    const data = { ...experimentDetails, ...folderDetails };
    const payload = {
      project_id: projectId,
      experiment_id: experimentId,
      folder_id: folderId,
      references: data.references,
      step_number: data.step_number,
      folder_route_scheme_id: data.folder_route_scheme_id,
      objective_of_reaction: convertChemicalFormulasToSimpleText(
        data.objective_of_reaction
      ),
      is_product_formed: data?.is_product_formed,
      // product_name: !showForm ? values.product_name : '',
      remarks: convertChemicalFormulasToSimpleText(values.remarks),
      reaction_scheme: data.reaction_scheme,
      status: experimentStatus as string,
      change_remarks: values.remark,
      section: 'raw_materials',
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
      experiment_category: data?.experiment_category,
      re_submit: false,
      experiment_precautions: values?.experiment_precautions,
    };

    let formdata = new FormData();

    formdata.append('project_id', payload.project_id as string);
    formdata.append('experiment_id', payload.experiment_id as string);
    formdata.append('is_edit', payload.is_edit ? 'true' : 'false');
    formdata.append('re_submit', 'false');
    formdata.append('folder_id', payload.folder_id as string);
    formdata.append('references', payload.references);
    formdata.append('step_number', payload.step_number);
    formdata.append('experiment_category', payload.experiment_category);
    formdata.append(
      'folder_route_scheme_id',
      String(payload.folder_route_scheme_id)
    );
    formdata.append(
      'objective_of_reaction',
      convertChemicalFormulasToSimpleText(payload.objective_of_reaction)
    );
    formdata.append(
      'is_product_formed',
      payload.is_product_formed ? 'true' : 'false'
    );
    formdata.append(
      'remarks',
      convertChemicalFormulasToSimpleText(payload.remarks)
    );
    formdata.append('reaction_scheme', payload.reaction_scheme);
    formdata.append('status', payload.status);
    formdata.append('change_remarks', payload.change_remarks);
    formdata.append('section', payload.section);
    formdata.append('experiment_precautions', payload.experiment_precautions);

    dispatch(createExperiment(formdata)).then((res: any) => {
      setIsUpdatePrecautions(false);
      setIsRemarksModalOpen(false);
      if (res?.payload?.success) {
        setIsEditing(false);
        dispatch(getExperimentsDetails({ id: experimentId })).then(
          (res: any) => {
            if (res?.payload.success) {
              dispatch(getFolderDetails({ id: folderId }));
            }
          }
        );
      }
    });
  };

  const handleUpdateRawMaterialWithPrecautions = (remark: string) => {
    if (isRemarksModalOpen) {
      const parentValues = parentForm.getFieldsValue();
      updateRawMaterialWithPrecautions({ remark, ...parentValues });
    } else {
      setIsRemarksModalOpen(true);
    }
  };

  const handleSave = () => {
    if (isEditing) {
      setUnsavedSections(
        unsavedSections.filter((sec) => sec !== SECTION.rawMaterials)
      );
      setIsUpdatePrecautions(true);
      handleUpdateRawMaterialWithPrecautions('');
    } else {
      setIsEditing(true);
      setUnsavedSections([...unsavedSections, SECTION.rawMaterials]);
    }
  };

  useEffect(() => {
    if (idToEdit) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [idToEdit]);

  useEffect(() => {
    if (!materialData.length) {
      dispatch(getInitMaterial({}));
    }
  }, [materialData]);

  return (
    <Card
      header={
        <SectionHeader
          disabled={isDisabled}
          isEditing={isEditing}
          isView={isView}
          onSave={handleSave}
          experimentStatus={experimentStatus}
          title="Raw material details"
          experimentId={experimentId}
        />
      }
    >
      <div className="flex flex-col gap-3 p-6">
        {(isCreate || (isView && isEditing)) && <Tabs tabs={tabs} />}
        {isView && !materialsList.length && <Empty />}
        {materialsList?.length > 0 && (
          <Form
            form={form}
            id="material_row_edit_form"
            // onFinish={handleUpdateMaterialRow}
            onValuesChange={handleFormValuesChange}
          >
            <Table columns={columns} dataSource={materialsList} bordered />
          </Form>
        )}{' '}
        <FormInput
          name="experiment_precautions"
          label="Experiment Precautions"
          defaultStyle
          disabled={isView && !isEditing}
          type="textarea"
          placeholder="Enter Precautions"
          rows={4}
        />
        {/*change remarks modal */}
        <RemarksModal
          isOpen={isRemarksModalOpen && isView && isUpdatePrecautions}
          onApprove={(value) => handleUpdateRawMaterialWithPrecautions(value)}
          loading={addMaterialsLoading}
          onClose={() => {
            setIsRemarksModalOpen(false);
            setIsUpdatePrecautions(false);
          }}
        />
        {!children ? '' : children}
      </div>
    </Card>
  );
}
