import dashboard from 'assets/icons/new_dashboard.svg';
import folder from 'assets/icons/new_folder.svg';
import projects from 'assets/icons/projects.svg';
import projects_active from 'assets/icons/projects_active.svg';
import experiment from 'assets/icons/experiment.svg';
import report from 'assets/icons/report.svg';
import users from 'assets/icons/users.svg';
import masters from 'assets/icons/masters.svg';
import dashboard_active from 'assets/icons/new_dashboard_active.svg';
import folder_active from 'assets/icons/new_folder_active.svg';
import experiment_active from 'assets/icons/experiment_active.svg';
import report_active from 'assets/icons/report_active.svg';
import users_active from 'assets/icons/users_active.svg';
import masters_active from 'assets/icons/masters_active.svg';
import ai from 'assets/newIcon/ai-chipset.svg';
import ai_active from 'assets/newIcon/ai-chipset_active.svg';

import {
  CHEMIST,
  PROJECT_MANAGER,
  SECTION_HEAD,
  EXECUTIVE_ACCESS,
  TECHNICAL_LEAD,
  ADMIN,
} from 'utilities/roleRights';

export type TNavLinks = {
  id?: number;
  label: string;
  icon: string;
  path: string;
  activeIcon?: string;
  access?: string[];
  isDisabled?: boolean;
};

export const NAVLINKS: TNavLinks[] = [
  {
    id: 0,
    label: 'Dashboard',
    icon: dashboard,
    activeIcon: dashboard_active,
    path: 'dashboard',
    access: [ADMIN],
    isDisabled: false,
  },
  {
    id: 1,
    label: 'Projects',
    icon: projects,
    activeIcon: projects_active,
    path: 'dashboard',
    access: [
      PROJECT_MANAGER,
      SECTION_HEAD,
      CHEMIST,
      EXECUTIVE_ACCESS,
      TECHNICAL_LEAD,
    ],
    isDisabled: false,
  },
  {
    id: 2,
    label: 'Folders',
    icon: folder,
    activeIcon: folder_active,
    path: 'folders',
    access: [SECTION_HEAD, CHEMIST, EXECUTIVE_ACCESS, TECHNICAL_LEAD],
    isDisabled: true,
  },
  {
    id: 3,
    label: 'Experiments',
    icon: experiment,
    activeIcon: experiment_active,
    path: 'experiments',
    access: [SECTION_HEAD, CHEMIST, EXECUTIVE_ACCESS, TECHNICAL_LEAD],
    isDisabled: true,
  },
  // {
  //   id: 4,
  //   label: 'Projects',
  //   icon: projects,
  //   activeIcon: projects_active,
  //   path: 'projects',
  //   access: [PROJECT_MANAGER],
  //   isDisabled: false,
  // },

  {
    id: 5,
    label: 'Users',
    icon: users,
    activeIcon: users_active,
    path: 'users',
    access: [ADMIN],
    isDisabled: false,
  },
  {
    id: 6,
    label: 'Masters',
    icon: masters,
    activeIcon: masters_active,
    path: 'masters',
    access: [ADMIN],
    isDisabled: false,
  },
  {
    id: 4,
    label: 'Reports',
    icon: report,
    activeIcon: report_active,
    path: 'reports',
    access: [ADMIN, EXECUTIVE_ACCESS],
    isDisabled: false,
  },
  {
    id: 7,
    label: 'Smart Summaries',
    icon: ai,
    activeIcon: ai_active,
    path: 'smart-summaries',
    access: [EXECUTIVE_ACCESS],
    isDisabled: false,
  },
];
