import LocalStorage from './localStorage';

export const apiUrl = process.env.REACT_APP_API_URL as string;
export const apiUrlAI = process.env.REACT_APP_API_URL_AI as string;

////https://new-atom.azurewebsites.net/api/summarizer

const encodeQueryData = (data: Record<string, any> | null = null): string => {
  if (data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return '?' + ret.join('&');
  }
  return '';
};

export const getLocalToken = (): string => {
  const user = LocalStorage.getItem('newatom_ELN');
  const authToken = user?.accessToken;
  if (authToken !== null && authToken !== '') {
    return `Bearer ${authToken}`;
  } else {
    return '';
  }
};

interface ApiClient {
  Urls: {
    apiUrl: string;
    signin: string;
    forgetPass: string;
    resetPass: string;
    changePass: string;
    projectList: string;
    projectDetail: string;
    createProject: string;
    removeProjectDoc: string;
    createProjectCompound: string;
    createFolder: string;
    folderList: string;
    folderRouteList: string;
    userList: string;
    folderDetails: string;
    industryTypes: string;
    clientDetails: string;
    uomDetails: string;
    experimentsList: string;
    createProcedure: string;
    procedureList: string;
    createAnalysis: string;
    analysisList: string;
    materialsList: string;
    createMaterials: string;
    getProjectCode: string;
    createExperiment: string;
    exprimentDetails: string;
    createProduct: string;
    productList: string;
    dashboardCount: string;
    userListAdmin: string;
    createUser: string;
    clientListAdmin: string;
    roleList: string;
    uomList: string;
    createClient: string;
    typeOfTest: string;
    approveRejectExperiment: string;
    summarize: string;
    paraphrase: string;
    additionalComment: string;
    removeRefFile: string;
    createUom: string;
    typeOfListCreate: string;
    typeOfList: string;
    experimentTimeline: string;
    experimentLimit: string;
    createExperimentLimit: string;
    experimentTabCounts: string;
    experimentLogs: string;
    centralLogs: string;
    materialList: string;
    createMaterial: string;
    getMaterial: string;
    getInitMaterial: string;
  };
  make: (
    url: string,
    method: string,
    params: any,
    auth?: boolean,
    type?: string
  ) => Promise<any>;
  get: (url: string, params: any, auth?: boolean) => Promise<any>;
  post: (
    url: string,
    params: any,
    auth?: boolean,
    type?: string
  ) => Promise<any>;
  put: (url: string, params: any, auth?: boolean) => Promise<any>;
  patch: (url: string, params: any, auth?: boolean) => Promise<any>;
  delete: (url: string, params: any, auth?: boolean) => Promise<any>;
}

const apiClient: ApiClient = {
  Urls: {
    apiUrl,
    signin: `${apiUrl}/auth/sign-in`,
    forgetPass: `${apiUrl}/auth/forgot-password`,
    resetPass: `${apiUrl}/auth/reset-password`,
    projectList: `${apiUrl}/projects/project-list`,
    projectDetail: `${apiUrl}/projects/project-detail`,
    createProject: `${apiUrl}/projects/create-project`,
    createProjectCompound: `${apiUrl}/projects/create-project-compound`,
    createFolder: `${apiUrl}/folders/create-folder`,
    folderList: `${apiUrl}/folders/folder-list`,
    folderRouteList: `${apiUrl}/masters/folder-routes`,
    industryTypes: `${apiUrl}/masters/industry-types`,
    userList: `${apiUrl}/users/user-list`,
    folderDetails: `${apiUrl}/folders/folder-detail`,
    clientDetails: `${apiUrl}/masters/client-details`,
    uomDetails: `${apiUrl}/masters/uom-details`,
    createProcedure: `${apiUrl}/experiments/create-experiment-procedure`,
    procedureList: `${apiUrl}/experiments/experiment-procedure-list`,
    createAnalysis: `${apiUrl}/experiments/create-experiment-analysis`,
    analysisList: `${apiUrl}/experiments/experiment-analysis-list`,
    materialsList: `${apiUrl}/experiments/experiment-materials-list`,
    createMaterials: `${apiUrl}/experiments/create-experiment-materials`,
    createExperiment: `${apiUrl}/experiments/create-experiment`,
    getProjectCode: `${apiUrl}/projects/project-code`,
    experimentsList: `${apiUrl}/experiments/experiment-list`,
    exprimentDetails: `${apiUrl}/experiments/experiment-detail`,
    createProduct: `${apiUrl}/experiments/create-experiment-products`,
    productList: `${apiUrl}/experiments/experiment-products-list`,
    dashboardCount: `${apiUrl}/admin/dashboard-counts`,
    userListAdmin: `${apiUrl}/admin/user-lists`,
    createUser: `${apiUrl}/admin/create-users`,
    typeOfTest: `${apiUrl}/masters/type-of-tests`,
    approveRejectExperiment: `${apiUrl}/experiments/approve-reject-experiment`,
    summarize: `${apiUrlAI}/summarizer`,
    paraphrase: `${apiUrl}/gen-ai/paraphrase`,
    clientListAdmin: `${apiUrl}/admin/client-lists`,
    createClient: `${apiUrl}/admin/create-clients`,
    additionalComment: `${apiUrl}/experiments/additional-comments`,
    roleList: `${apiUrl}/admin/role-lists`,
    uomList: `${apiUrl}/admin/uom-lists`,
    materialList: `${apiUrl}/admin/material-lists`,
    removeRefFile: `${apiUrl}/experiments/remove-reference`,
    createUom: `${apiUrl}/admin/create-uoms`,
    createMaterial: `${apiUrl}/admin/create-materials`,
    typeOfListCreate: `${apiUrl}/admin/create-type-of-tests`,
    typeOfList: `${apiUrl}/admin/type-of-test-lists`,
    experimentTimeline: `${apiUrl}/experiments/experiment-timelines`,
    experimentLimit: `${apiUrl}/admin/experiment-limits`,
    createExperimentLimit: `${apiUrl}/admin/create-experiments-limit`,
    experimentTabCounts: `${apiUrl}/experiments/experiment-filter-counts`,
    experimentLogs: `${apiUrl}/experiments/experiment-logs`,
    centralLogs: `${apiUrl}/reports/central-logs`,
    changePass: `${apiUrl}/settings/update-password`,
    removeProjectDoc: `${apiUrl}/projects/delete-project-document`,
    getMaterial: `${apiUrl}/masters/material-fields`,
    getInitMaterial: `${apiUrl}/masters/fetch-material-list`
  },

  make: async (
    url: string,
    method: string,
    params: any,
    auth: boolean = false,
    type: string = ''
  ): Promise<any> => {
    let headers: HeadersInit = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    let authHeaders: HeadersInit = {
      Authorization: getLocalToken(),
    };

    if (auth) {
      headers = {
        ...headers,
        ...authHeaders,
      };
    }

    if (method === 'GET') {
      let queryParams = '';
      if (Object.keys(params || {}).length) {
        queryParams = encodeQueryData(params);
      }
      return fetch(url + queryParams, {
        method,
        headers,
      })
        .then((response) => response.json())
        .catch((e) => e);
    } else {
      return fetch(url, {
        method,
        headers: type === 'file' ? authHeaders : headers,
        body: type === 'file' ? params : JSON.stringify(params),
      })
        .then((response) => response.json())
        .catch((e) => e);
    }
  },

  get: (url: string, params: any, auth: boolean = false): Promise<any> => {
    return apiClient.make(url, 'GET', params, auth);
  },

  post: (
    url: string,
    params: any,
    auth: boolean = false,
    type: string = ''
  ): Promise<any> => {
    return apiClient.make(url, 'POST', params, auth, type);
  },

  put: (url: string, params: any, auth: boolean = false): Promise<any> => {
    return apiClient.make(url, 'PUT', params, auth);
  },

  patch: (url: string, params: any, auth: boolean = false): Promise<any> => {
    return apiClient.make(url, 'PATCH', params, auth);
  },

  delete: (url: string, params: any, auth: boolean = false): Promise<any> => {
    return apiClient.make(url, 'DELETE', params, auth);
  },
};

export default apiClient;
