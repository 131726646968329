import clientsImg from 'assets/images/masters/clients.png';
import uomImg from 'assets/images/masters/uom.png';
import typeOfTestImg from 'assets/images/masters/type_of_test.png';
import crossHandsImg from 'assets/images/masters/cross_hands.png';

export type TMaster = {
  id: number;
  label: string;
  subLabel: string;
  path: string;
  img: string;
};

export const MASTERS: TMaster[] = [
  {
    id: 1,
    label: 'Client Name',
    subLabel:
      'The Client Name refers to the official individual or organization we are working with for this project or service',
    path: 'clients',
    img: clientsImg,
  },
  {
    id: 2,
    label: 'UOM',
    subLabel:
      'UoM specifies the standard unit used to quantify the products or services involved in the transaction or project.',
    path: 'uom',
    img: uomImg,
  },
  {
    id: 4,
    label: 'Type of Test',
    subLabel:
      '"Type of Test" defines the specific category or method of testing applied to evaluate the performance, quality, or functionality.',
    path: 'type-of-test',
    img: typeOfTestImg,
  },
  {
    id: 5,
    label: 'Experiment Limit Per Folder',
    subLabel:
      '"Experiment Limit Per Folder" refers to the maximum number of experiments that can be stored or managed within a single folder.',
    path: '#', //'folder_limit',
    img: crossHandsImg,
  },
  {
    id: 6,
    label: 'Materials',
    subLabel:
      '"Experiment Limit Per Folder" refers to the maximum number of experiments that can be stored or managed within a single folder.',
    path: 'materials',
    img: crossHandsImg,
  },
];
