import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  analysisList,
  createAnalysis,
  createProcedure,
  procedureList,
  createMaterials,
  materialsList,
  experimentCreate,
  experimentsList,
  experimentDetails,
  productList,
  productCreate,
  typeOfTest,
  approveRejectExp,
  paraphrase,
  additionalComment,
  removeRefFile,
  experimentTimeline,
  experimentTabCounts,
  experimentLogs,
} from './api';

interface ProjectState {
  addProcedureLoading: boolean;
  experimentListLoading: boolean;
  experimentList: any[];
  procedureList: any[];
  procedureListLoading: boolean;
  addAnalysisLoading: boolean;
  analysisList: any[];
  analysisListLoading: boolean;
  pagination: any;
  addMaterialsLoading: boolean;
  materialsList: any[];
  materialsListLoading: boolean;
  createExperimentLoading: boolean;
  experimentDetails: { [key: string]: any };
  experimentDetailsLoading: boolean;
  addProductLoading: boolean;
  productList: any[];
  productListLoading: boolean;
  typeOfTestLoading: boolean;
  typeOfTestList: any[];
  experimentId: string | null;
  experimentRoot: string | null;
  approveRejectLoading: boolean;
  paraphraseSteps: any[];
  paraphraseStepsLoading: boolean;
  addAdditionalCommentLoading: boolean;
  removeRefFileLoading: boolean;
  experimentTimelineLoading: boolean;
  experimentTimelineData: any[];
  experimentTabCounts: any;
  experimentTabCountsLoading: boolean;
  experimentLogs: any[];
  experimentLogsLoading: boolean;
  isExternalChemist?: boolean;
  hasSubmitAccess?: boolean;
  hasApproveAccess?: boolean;
}

const initialState: ProjectState = {
  addProcedureLoading: false,
  experimentListLoading: false,
  experimentList: [],
  procedureList: [],
  procedureListLoading: false,
  pagination: {},
  addAnalysisLoading: false,
  analysisListLoading: false,
  analysisList: [],
  addMaterialsLoading: false,
  materialsList: [],
  materialsListLoading: false,
  createExperimentLoading: false,
  experimentDetails: {},
  experimentDetailsLoading: false,
  addProductLoading: false,
  productList: [],
  productListLoading: false,
  typeOfTestList: [],
  typeOfTestLoading: false,
  experimentId: null,
  approveRejectLoading: false,
  paraphraseSteps: [],
  paraphraseStepsLoading: false,
  addAdditionalCommentLoading: false,
  removeRefFileLoading: false,
  experimentTimelineData: [],
  experimentTimelineLoading: false,
  experimentTabCounts: {},
  experimentTabCountsLoading: false,
  experimentLogs: [],
  experimentLogsLoading: false,
  isExternalChemist: false,
  hasSubmitAccess: false,
  hasApproveAccess: false,
  experimentRoot: null,
};

const actions = {
  CREATE_PROCEDURE: 'folder/CREATE_PROCEDURE',
  PROCEDURE_LIST: 'folder/PROCEDURE_LIST',
  CREATE_ANALYSIS: 'folder/CREATE_ANALYSIS',
  ANALYSIS_LIST: 'folder/ANALYSIS_LIST',
  MATERIALS_LIST: 'getMaterialsList/MATERIALS_LIST',
  CREATE_MATERIALS: 'addMaterials/CREATE_MATERIALS',
  CREATE_EXPERIMENT: 'addMaterials/CREATE_EXPERIMENT',
  ADDITIONAL_COMMENT: 'addMaterials/ADDITIONAL_COMMENT',
  CREATE_MATERIAL: 'experiment/CREATE_MATERIAL',
  MATERIAL_LIST: 'experiment/MATERIAL_LIST',
  EXPERIMENT_LIST: 'experiment/EXPERIMENT_LIST',
  EXPERIMENT_DETAILS: 'experiment/EXPERIMENT_DETAILS',
  CREATE_PRODUCT: 'experiment/CREATE_PRODUCT',
  PRODUCT_LIST: 'experiment/PRODUCT_LIST',
  TYPE_OF_TEST: 'experiment/TYPE_OF_TEST',
  APPROVE_REJECT: 'experiment/APPROVE_REJECT',
  PARAPHRASE: 'experiment/PARAPHRASE',
  REMOVE_REF_FILE: 'experiment/REMOVE_REF_FILE',
  EXPERIMENT_TIMELINE: 'experiment/EXPERIMENT_TIMELINE',
  EXPERIMENT_TAB_COUNT: 'experiment/EXPERIMENT_TAB_COUNT',
  EXPERIMENT_LOGS: 'experiment/EXPERIMENT_LOGS',
};

export const addProcedure = createAsyncThunk(
  actions.CREATE_PROCEDURE,
  async (payload: Record<string, any>) => {
    const response = await createProcedure(payload);
    return response;
  }
);

export const getProdecedureList = createAsyncThunk(
  actions.PROCEDURE_LIST,
  async (payload: Record<string, any>) => {
    const response = await procedureList(payload);
    return response;
  }
);

export const addAnalysis = createAsyncThunk(
  actions.CREATE_ANALYSIS,
  async (payload: Record<string, any>) => {
    const response = await createAnalysis(payload);
    return response;
  }
);

export const getAnalysisList = createAsyncThunk(
  actions.ANALYSIS_LIST,
  async (payload: Record<string, any>) => {
    const response = await analysisList(payload);
    return response;
  }
);

export const addMaterials = createAsyncThunk(
  actions.CREATE_MATERIALS,
  async (payload: Record<string, any>) => {
    const response = await createMaterials(payload);
    return response;
  }
);

export const createExperiment = createAsyncThunk(
  actions.CREATE_EXPERIMENT,
  async (payload: Record<string, any>) => {
    const response = await experimentCreate(payload);
    return response;
  }
);

export const addAdditionalComment = createAsyncThunk(
  actions.ADDITIONAL_COMMENT,
  async (payload: Record<string, any>) => {
    const response = await additionalComment(payload);
    return response;
  }
);

export const getMaterialsList = createAsyncThunk(
  actions.MATERIALS_LIST,
  async (payload: Record<string, any>) => {
    const response = await materialsList(payload);
    return response;
  }
);

export const getExperimentsList = createAsyncThunk(
  actions.EXPERIMENT_LIST,
  async (payload: Record<string, any>) => {
    const response = await experimentsList(payload);
    return response;
  }
);

export const getExperimentsDetails = createAsyncThunk(
  actions.EXPERIMENT_DETAILS,
  async (payload: Record<string, any>) => {
    const response = await experimentDetails(payload);
    return response;
  }
);

export const getProductsList = createAsyncThunk(
  actions.PRODUCT_LIST,
  async (payload: Record<string, any>) => {
    const response = await productList(payload);
    return response;
  }
);

export const addProduct = createAsyncThunk(
  actions.CREATE_PRODUCT,
  async (payload: Record<string, any>) => {
    const response = await productCreate(payload);
    return response;
  }
);

export const getTypeOfTestList = createAsyncThunk(
  actions.TYPE_OF_TEST,
  async (payload: Record<string, any>) => {
    const response = await typeOfTest(payload);
    return response;
  }
);

export const approveRejectExperiment = createAsyncThunk(
  actions.APPROVE_REJECT,
  async (payload: Record<string, any>) => {
    const response = await approveRejectExp(payload);
    return response;
  }
);

export const generateParaphrase = createAsyncThunk(
  actions.PARAPHRASE,
  async (payload: Record<string, any>) => {
    const response = await paraphrase(payload);
    return response;
  }
);

export const deleteRefFile = createAsyncThunk(
  actions.REMOVE_REF_FILE,
  async (payload: Record<string, any>) => {
    const response = await removeRefFile(payload);
    return response;
  }
);

export const getExperimentTimeline = createAsyncThunk(
  actions.EXPERIMENT_TIMELINE,
  async (payload: Record<string, any>) => {
    const response = await experimentTimeline(payload);
    return response;
  }
);

export const getExperimentTabCounts = createAsyncThunk(
  actions.EXPERIMENT_TAB_COUNT,
  async (payload: Record<string, any>) => {
    const response = await experimentTabCounts(payload);
    return response;
  }
);

export const getExperimentLogs = createAsyncThunk(
  actions.EXPERIMENT_LOGS,
  async (payload: Record<string, any>) => {
    const response = await experimentLogs(payload);
    return response;
  }
);

export const experimentsSlice = createSlice({
  name: 'experiments',
  initialState,
  reducers: {
    //set experiment id
    setExperimentId: (state, action) => {
      state.experimentId = action.payload;
    },

    //set experiment root
    setExperimentRoot: (state, action) => {
      state.experimentRoot = action.payload;
    },

    //empty all lists in create
    resetExperimentState: (state) => {
      return initialState;
    },

    //checking if user is not in authors array should be mark true to incluse him in authors array
    setIsExternalChemist: (state, action) => {
      state.isExternalChemist = action.payload;
    },

    //setting submit permissions
    setHasSubmitAccess: (state, action) => {
      state.hasSubmitAccess = action.payload;
    },

    //setting approve permissions
    setHasApproveAccess: (state, action) => {
      state.hasApproveAccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    //get experiment list
    builder
      .addCase(getExperimentsList.pending, (state) => {
        state.experimentListLoading = true;
      })
      .addCase(getExperimentsList.fulfilled, (state, action) => {
        state.experimentListLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.experimentList = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getExperimentsList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.experimentListLoading = false;

        message.error(msg);
      });

    // create prodecure
    builder
      .addCase(addProcedure.pending, (state) => {
        state.addProcedureLoading = true;
      })
      .addCase(addProcedure.fulfilled, (state, action) => {
        state.addProcedureLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addProcedure.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.addProcedureLoading = false;

        message.error(msg);
      });

    // get prodecure list
    builder
      .addCase(getProdecedureList.pending, (state) => {
        state.procedureListLoading = true;
      })
      .addCase(getProdecedureList.fulfilled, (state, action) => {
        state.procedureListLoading = false;
        const { success, message: msg, data, pagination } = action.payload;
        if (success) {
          state.procedureList = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getProdecedureList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.procedureListLoading = false;

        message.error(msg);
      });

    // get experiment tab count
    builder
      .addCase(getExperimentTabCounts.pending, (state) => {
        state.experimentTabCountsLoading = true;
      })
      .addCase(getExperimentTabCounts.fulfilled, (state, action) => {
        state.experimentTabCountsLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.experimentTabCounts = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getExperimentTabCounts.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.experimentTabCountsLoading = false;

        message.error(msg);
      });

    // create analysis
    builder
      .addCase(addAnalysis.pending, (state) => {
        state.addAnalysisLoading = true;
      })
      .addCase(addAnalysis.fulfilled, (state, action) => {
        state.addAnalysisLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addAnalysis.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.addAnalysisLoading = false;

        message.error(msg);
      });

    // get analysis list
    builder
      .addCase(getAnalysisList.pending, (state) => {
        state.analysisListLoading = true;
      })
      .addCase(getAnalysisList.fulfilled, (state, action) => {
        state.analysisListLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.analysisList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getAnalysisList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.analysisListLoading = false;

        message.error(msg);
      });

    // create material
    builder
      .addCase(addMaterials.pending, (state) => {
        state.addMaterialsLoading = true;
      })
      .addCase(addMaterials.fulfilled, (state, action) => {
        state.addMaterialsLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addMaterials.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.addMaterialsLoading = false;

        message.error(msg);
      });

    // get material list
    builder
      .addCase(getMaterialsList.pending, (state) => {
        state.materialsListLoading = true;
      })
      .addCase(getMaterialsList.fulfilled, (state, action) => {
        state.materialsListLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.materialsList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getMaterialsList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.materialsListLoading = false;

        message.error(msg);
      });

    // get experiment details
    builder
      .addCase(getExperimentsDetails.pending, (state) => {
        state.experimentDetailsLoading = true;
      })
      .addCase(getExperimentsDetails.fulfilled, (state, action) => {
        state.experimentDetailsLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.experimentDetails = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getExperimentsDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.experimentDetailsLoading = false;

        message.error(msg);
      });

    // create experiment
    builder
      .addCase(createExperiment.pending, (state) => {
        state.createExperimentLoading = true;
      })
      .addCase(createExperiment.fulfilled, (state, action) => {
        state.createExperimentLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createExperiment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createExperimentLoading = false;

        message.error(msg);
      });

    // create product
    builder
      .addCase(addProduct.pending, (state) => {
        state.addProductLoading = true;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.addProductLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addProduct.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.addProductLoading = false;

        message.error(msg);
      });

    // get product list
    builder
      .addCase(getProductsList.pending, (state) => {
        state.productListLoading = true;
      })
      .addCase(getProductsList.fulfilled, (state, action) => {
        state.productListLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.productList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getProductsList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.productListLoading = false;

        message.error(msg);
      });

    // get type of test list
    builder
      .addCase(getTypeOfTestList.pending, (state) => {
        state.typeOfTestLoading = true;
      })
      .addCase(getTypeOfTestList.fulfilled, (state, action) => {
        state.typeOfTestLoading = false;
        const { success, message: msg, data } = action.payload;
        if (success) {
          state.typeOfTestList = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getTypeOfTestList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.typeOfTestLoading = false;

        message.error(msg);
      });

    // approve reject experiment
    builder
      .addCase(approveRejectExperiment.pending, (state) => {
        state.approveRejectLoading = true;
      })
      .addCase(approveRejectExperiment.fulfilled, (state, action) => {
        state.approveRejectLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(approveRejectExperiment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.approveRejectLoading = false;

        message.error(msg);
      });

    //get paraphrase steps
    builder
      .addCase(generateParaphrase.pending, (state) => {
        state.paraphraseStepsLoading = true;
      })
      .addCase(generateParaphrase.fulfilled, (state, action) => {
        state.paraphraseStepsLoading = false;
        const { success, message: msg, results } = action.payload as any;
        if (success) {
          state.paraphraseSteps = results;
        } else {
          message.error(msg);
        }
      })
      .addCase(generateParaphrase.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.paraphraseStepsLoading = false;

        message.error(msg);
      });

    // add additional comment
    builder
      .addCase(addAdditionalComment.pending, (state) => {
        state.addAdditionalCommentLoading = true;
      })
      .addCase(addAdditionalComment.fulfilled, (state, action) => {
        state.addAdditionalCommentLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addAdditionalComment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.addAdditionalCommentLoading = false;

        message.error(msg);
      });

    // remove delete reference file
    builder
      .addCase(deleteRefFile.pending, (state) => {
        state.removeRefFileLoading = true;
      })
      .addCase(deleteRefFile.fulfilled, (state, action) => {
        state.removeRefFileLoading = false;
        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(deleteRefFile.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.removeRefFileLoading = false;

        message.error(msg);
      });

    // get experiment timeline
    builder
      .addCase(getExperimentTimeline.pending, (state) => {
        state.experimentTimelineLoading = true;
      })
      .addCase(getExperimentTimeline.fulfilled, (state, action) => {
        state.experimentTimelineLoading = false;
        const { success, message: msg, timelines } = action.payload as any;

        if (success) {
          state.experimentTimelineData = timelines;
        } else {
          message.error(msg);
        }
      })
      .addCase(getExperimentTimeline.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.experimentTimelineLoading = false;

        message.error(msg);
      });

    // get experiment logs
    builder
      .addCase(getExperimentLogs.pending, (state) => {
        state.experimentLogsLoading = true;
      })
      .addCase(getExperimentLogs.fulfilled, (state, action) => {
        state.experimentLogsLoading = false;
        const { success, message: msg, data } = action.payload;

        if (success) {
          state.experimentLogs = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getExperimentLogs.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.experimentLogsLoading = false;

        message.error(msg);
      });
  },
});

export const {
  setExperimentId,
  resetExperimentState,
  setIsExternalChemist,
  setHasApproveAccess,
  setHasSubmitAccess,
  setExperimentRoot
} = experimentsSlice.actions;
export default experimentsSlice.reducer;
